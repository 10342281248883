import styled , { css, keyframes } from "styled-components";
import { IoIosClose } from 'react-icons/io';

const fadeInOut = keyframes`
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
`;

const vibrateAnimation = keyframes`
  0% { transform: translateX(0); }
  25% { transform: translateX(-2px); }
  50% { transform: translateX(2px); }
  75% { transform: translateX(-2px); }
  100% { transform: translateX(0); }
`;

const animationCss = css`
  animation: ${vibrateAnimation} 0.5s linear infinite;
`;

export const Container = styled.div`
    display: flex;
    height: 100% !important;
    background: linear-gradient(to bottom right, white 0%, #e6e4ff 70%);
    border-radius: 2rem;
    box-sizing: border-box;
    padding: 0;
    margin: 0.7rem;
    @media screen and (min-width:320px) and (max-width:1080px){
        flex-direction    : column;
    }
`;

export const NewsBadge = styled.div`
  margin-top: 7px;
  background-color: red; 
  color: white; 
  padding: 7px; 
  border-radius: 10px; 
  font-weight: 700;
  font-size: 0.7rem;
  text-align: center;
  right: 0; 
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  opacity: 0;
  animation: ${({ hasNotifications }) => hasNotifications ? css`${fadeInOut} 5s ease-in-out infinite` : 'none'};
  z-index: 10;
`;

export const NotificationCount = styled.span`
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 0.5em;
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 0.6rem;
  font-weight: bold;
  border: 3px solid white;
  min-width: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  position: fixed;
  top: 3%;
  right: 20px;
  z-index: 1000;
  ${({ hasNotifications }) => hasNotifications && animationCss}
`;

export const ButtonContent = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const IconButton = styled.button`
  background-color: #F6D42D;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.26);

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #FFAF01;
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const PopupContainer = styled.div`
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 40%;
  height: 80%;
  background-color: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media screen and (min-width:320px) and (max-width:1080px){
    width : 80%;
  }
`;

export const NotificationsList = styled.div`
  overflow-y: auto;
  width: 100%;
  height: 100%;
  
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

export const CloseButton = styled(IoIosClose)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;