import React, { useRef, useState, useEffect } from 'react'
import Sidebar from '../componentsDashboard/Sidebar';
import MainContent from '../componentsDashboard/MainContent';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { 
  Container,
  NotificationCount,
  ButtonWrapper,
  IconButton,
  NewsBadge,
  ButtonContent,
  Backdrop,
  PopupContainer,
  CloseButton,
  NotificationsList
} from './AccountDashBoardElements';
import { ContentProvider } from '../componentsDashboard/Context/ContentContext';
import { UserAuth } from '../Context/AuthContext';

const NotificationsPopup = ({ notifications, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <>
      <Backdrop onClick={onClose} />
      <PopupContainer>
        <CloseButton size={30} onClick={onClose} />
        <h2 style={{marginBottom: "10px"}}>Notizie</h2>
        <NotificationsList>
          {notifications.map((notification, index) => (
            <div key={index}>
              <h3>{notification.titolo}</h3>
              <p>{notification.descrizione}</p>
              <a href={notification.link} target="_blank" rel="noopener noreferrer">Leggi</a>
              <p>Data: {new Date(notification.data).toLocaleDateString()}</p>
            </div>
          ))}
        </NotificationsList>
      </PopupContainer>
    </>
  );
};

const NotificationIcon = ({ notificationCount, notifications }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleIconClick = () => {
    if (isNotificationsValid) {
      setIsPopupOpen(true);
    }
    // Aggiungi qui la logica per "leggere" le notifiche se necessario
  };

  const handleClose = () => {
    setIsPopupOpen(false);
    // Aggiungi qui la logica per resettare l'animazione delle notifiche se necessario
  };

  const isNotificationsValid = notifications && Array.isArray(notifications);

  return (
    <ButtonWrapper hasNotifications={notificationCount > 0 && !isPopupOpen}>
      <ButtonContent>
        <>
          <IconButton onClick={handleIconClick}>
            <IoIosNotificationsOutline size={34} color='black' />
          </IconButton>
          {notificationCount > 0 && (
            <NotificationCount>
              {notificationCount}
            </NotificationCount>
          )}
        </>
        {notificationCount > 0 && !isPopupOpen && (
          <NewsBadge hasNotifications={true}>
            Novità
          </NewsBadge>
        )}
      </ButtonContent>
      <NotificationsPopup
        notifications={notifications}
        isOpen={isPopupOpen}
        onClose={handleClose}
      />
    </ButtonWrapper>
  );
};

const Dashboard = () => {
  const mainContentRef = useRef(null);

  const [currentMonth] = useState((new Date()).getMonth() + 1);
  const [currentYear] = useState((new Date()).getFullYear());

  const [notifications, setNotifications] = useState();

  const [notificationCount, setNotificationCount] = useState(0);

  const {getNewsForCurrentMonth} = UserAuth();

  useEffect(() => {
    if (notifications) {
      setNotificationCount(notifications.length);
    }
  }, [notifications]);

  useEffect(() => {
    const loadNews = async () => {
      const notizie = await getNewsForCurrentMonth(currentYear, currentMonth);
      setNotifications(notizie.length > 0 ? notizie : null);
    };

    loadNews();
  }, [currentMonth, currentYear]);

  return (
    <>  
        <ContentProvider>
          <Container>
            <Sidebar mainContentRef={mainContentRef}/>
            <MainContent ref={mainContentRef}/>
            <NotificationIcon notificationCount={notificationCount} notifications={notifications}/>
          </Container>
        </ContentProvider>
    </>
    
  )
}

export default Dashboard