import styled, { keyframes } from 'styled-components';
import { themeColor,darkThemeColor } from './../utils';


export const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  //align-items: center;
  align-self: center;
  margin: 2rem;
`;

export const InputContainer = styled.div`
  display: flex;
  //flex-direction: column;
  //align-items: center;
  margin: 0.5rem;
`;

export const Input = styled.input`
  padding: 0.5rem;
  margin: 0.5rem;
  margin-left: auto;
  font-size: 1.5rem;
  width: 70%;
  border: 1px solid black;
  border-radius: 0.5rem;
`;


export const Button = styled.button`
  /*padding: 0.5rem;
  margin: 0.5rem;
  font-size: 1rem;
  width: 15%;
  border: 1px solid black;*/
    padding : 0.3rem 1rem;
    border-radius: 1rem;
    font-weight: 500;
    font-size: 20px;
    margin-left: 2px;
    width: 15%;
    color: white;
    background-color: ${themeColor};
    cursor: pointer;

    &:hover {
        transition: all 0.2s ease-in-out;    
        background:  ${darkThemeColor};
    }
`;

export const TextCheckBox = styled.h1`
    color: black;
    //font: 1.5rem;
    font-size:1.5rem;
    font-weight: 400;
    margin: 0.8rem 0 0.5rem 0;
    padding: 0;
    margin-bottom: 1rem;

    /*@media screen and (max-width: 768px){
        font-size: 8px;   
    }*/
`;

export const SelectContainer = styled.div`
  position: relative;
  width: 200px;
  margin: 0.5rem;
`;

export const Select = styled.select`
  width: 100%;
  padding: 0.5rem;
  font-size: 1.5rem;
  border: 1px solid black;
  //border: 1px solid #ddd;
  border-radius: 0.5rem;
  appearance: none;
  background-color: #fff;
`;

export const CartoonyOption = styled.option`
  font-size: 1.5rem;
  background-color: #fff;
  color: #000;
  padding: 0.5rem;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem;
  cursor: pointer;
`;

export const Checkbox = styled.div`
  width: 30px;
  height: 30px;
  margin-left: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  background-color: ${(props) => (props.isChecked ? '#00FF00' : '#FF0000')};
  animation: ${(props) => (props.isChecked ? keyframesCheck : keyframesUncheck)} 0.2s ease-in-out;
`;

export const keyframesCheck = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
`;

export const keyframesUncheck = keyframes`
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
`;

export const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin: 50px 0;
  text-transform: uppercase;
  letter-spacing: 2px;

  &:after {
    content: "";
    display: block;
    width: 80px;
    height: 6px;
    margin: 20px auto;
    background-color: ${themeColor};
    border-radius: 10px;
  }
`;

export const SaveButton = styled.button`
    margin-top: 2rem;
    padding : 0.3rem 1rem;
    border-radius: 1rem;
    font-weight: 500;
    font-size: 1.5rem;
    color: white;
    background-color: ${themeColor};
    cursor: pointer;
    align-self: center;
    width: 100%;

    /*@media screen and (max-width: 768px){
        font-size: 10px;  
    }*/
    &:hover {
        transition: all 0.2s ease-in-out;    
        background:  ${darkThemeColor};
    }
`


