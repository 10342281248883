import React from 'react'
import ScrollToTop from '../components/ScrollToTop'
import Candidati from '../components/Candidati'
import { useParams } from 'react-router';


const CandidatiPage = () => {
  const { id } = useParams();
  
  return (
    <>
      <ScrollToTop />
        <Candidati idStreamer={id}/>
    </>
  )
}

export default CandidatiPage