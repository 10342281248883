import React, { useState } from 'react'
import { MentorshipContainer, MentorshipWrapper, LockIcon, MentorshipContent, InfoContainer, InfoText, OreContainer, CategoriaOre, InsegnanteFoto, MentorshipTitleWrapper, NomeMentorship, Divider, Mask, InfoOre, NomeCategoriaOre } from './PaginaMentorshipelements'
import Icon1 from '../../Images/foto-dani.png'

const PaginaMentorship = () => {

  const [listaMentorship] = useState([
    { 
      id: 1, 
      categoria: 'Mentorship di trading', 
      nomeInsegnante: 'Daniele Cannizzaro', 
      campoCompetenza: 'Trading Forex & Index',
      esperienza: '2 anni',
      foto: Icon1,
      categorieOre: [
        { nomeCategoria: 'Base', oreMese: 1, costoOrario: 20 },
        { nomeCategoria: 'Intermedio', oreMese: 2, costoOrario: 18 },
        { nomeCategoria: 'Avanzato', oreMese: 3, costoOrario: 17 },
        { nomeCategoria: 'Professionista', oreMese: 4, costoOrario: 15 },
      ],
      bloccata: false 
    },
  ]);

  const handleClick = (categoria, mentorship) => {
    alert("Funzione non ancora implementata!");
  };

  return (
    <MentorshipContainer>
        {listaMentorship.map(mentorship => (
            <MentorshipWrapper key={mentorship.id}>
              {mentorship.bloccata && <Mask />}
              {mentorship.bloccata && <LockIcon />}
              <MentorshipTitleWrapper>
                <NomeMentorship>{mentorship.categoria}</NomeMentorship>
              </MentorshipTitleWrapper>
              <MentorshipContent>
                <InfoContainer>
                  <InsegnanteFoto src={mentorship.foto} alt={mentorship.nomeInsegnante} />
                  <InfoText>{mentorship.nomeInsegnante}</InfoText>
                  <InfoText>{mentorship.campoCompetenza}</InfoText>
                  <InfoText>{mentorship.esperienza} di esperienza</InfoText>
                </InfoContainer>
                <Divider />
                <OreContainer>
                  {mentorship.categorieOre.map((categoria, index) => (
                    <CategoriaOre key={index} onClick={() => handleClick(categoria, mentorship)}>
                      <div className="nomeCategoria">
                        <NomeCategoriaOre>{categoria.nomeCategoria}</NomeCategoriaOre>
                      </div>
                      <InfoOre> <b>{categoria.oreMese} ore</b> / mese</InfoOre>
                      <InfoOre> <b>{categoria.costoOrario.toFixed(2).replace('.', ',')}€</b> / ora</InfoOre>
                    </CategoriaOre>
                  ))}
                </OreContainer>
              </MentorshipContent>
            </MentorshipWrapper>
        ))}
    </MentorshipContainer>
  )
}

export default PaginaMentorship