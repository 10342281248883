import styled from 'styled-components';
import { darkThemeColor, themeColor } from '../utils';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    margin: 2rem;
    width: 100%;

    @media screen and (min-width:320px) and (max-width:1080px){
        flex-direction: column;
        width: fit-content;
    } 
`;

export const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%; 
    margin-left: 5%;

    @media screen and (min-width:320px) and (max-width:1080px){
        width: 100%; 
        margin-left: 0;
    } 
`;

export const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%; 
    margin-left: 15%;

    @media screen and (min-width:320px) and (max-width:1080px){
        width: 100%; 
        margin-left: 0;
    } 
`;

export const CalendarContainer = styled.div`
    margin-bottom: 20px;
    align-self: center;

    @media screen and (min-width:320px) and (max-width:1080px){
        width: 100%; 
    } 
`;

export const TimeList = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;

    @media screen and (min-width:320px) and (max-width:1080px){
        grid-template-columns: repeat(1, 1fr);
    } 
`;

export const TimeItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto; 
    max-width: 100%;
    padding: 10px;
    margin: 5px 0;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: ${props => (props.isOccupied ? '#f8d7da' : '#d4edda')};
    color: ${props => (props.isOccupied ? '#721c24' : '#155724')};
    cursor: ${props => (props.isOccupied ? 'not-allowed' : 'pointer')};
    transition: transform 0.2s, box-shadow 0.2s;

    &:hover {
        transform: ${props => (props.isOccupied ? 'none' : 'scale(1.05)')};
        box-shadow: ${props => (props.isOccupied ? 'none' : '0 2px 8px rgba(0, 0, 0, 0.2)')};
    }
`;

export const Time = styled.div`
    font-size: 1.2rem;
    font-weight: bold;
`;

export const Leaders = styled.div`
    font-size: 0.9rem;
    align-self: center;
    text-align: center;
`;

export const LeaderTitle = styled.div`
    margin-top: 6px;
    font-weight: bold;
    margin-bottom: 5px;
    align-self: center;
`;

export const EventForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const EventList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    width: 130%; 

    @media screen and (min-width:320px) and (max-width:1080px){
        width: 100%; 
    } 
`;

export const EventItem = styled.div`
    position: relative;
    background-color: ${props => (props.completed ? '#d4edda' : '#f8d7da')};
    color: ${props => (props.completed ? '#155724' : '#721c24')};
    margin: 10px 0;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;

    &:hover {
        transform: scale(1.05);
    }
`;

export const EventHeader = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    width: 100%;
    margin-bottom: 15px;
`;

export const EventName = styled.div`
    text-align: left;
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 7px;
`;

export const EventDateString = styled.div`
    text-align: left;
    color: white;
`;

export const EventDate = styled.div`
    margin-left: 5px;
    text-align: left;
    color: yellow;
`;

export const EventHour = styled.div`
    text-align: left;
    font-size: 1.1rem;
    font-weight: 500;
`;

export const EventBottom = styled.div`
    display: flex;
    width: 100%;
    font-size: 1.2rem;
    font-weight: 600;
`;


export const ModalBackground = styled.div`
    display: ${props => (props.show ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
`;

export const ModalContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 40px;
    z-index: 1001;
    border-radius: 10px;
    max-width: 90vw;
`;

export const CloseModalButton = styled.button`
    position: absolute;
    top: 15px;
    right: 15px;
    border: none;
    background-color: transparent;
    font-size: 1.5rem;
    cursor: pointer;
    color: #6c757d; 
    transition: color 0.2s;

    &:hover {
        color: #000;
    }
`;

export const StyledInput = styled.input`
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;

    &:focus {
        border-color: ${themeColor};
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
`;

export const StyledButton = styled.button`
    padding: 10px 20px;
    background-color: ${themeColor};
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;

    &:hover {
        background-color: ${darkThemeColor};
    }

    @media screen and (min-width:320px) and (max-width:1080px){
        font-size: 14px;
    }
`;

export const TitleModal = styled.h2`
    font-size: 1.6rem;
    margin-top: 15px;
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;

    @media screen and (min-width:320px) and (max-width:1080px){
        font-size: 1rem;
    }
`

export const SubTitle = styled.h2`
    font-size: 1.6rem;
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;

    @media screen and (min-width:320px) and (max-width:1080px){
        font-size: 1rem;
    }
`

export const DeleteButton = styled.button`
    padding: 10px 20px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: darkred;
    }
`

export const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
`;


export const CheckboxController = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0; 
    cursor: pointer;
    font-weight: bold;
    color: #333;
`;

export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
    margin-right: 10px;
    appearance: none;
    position: relative;
    width: 24px;
    height: 24px;
    border: 2px solid #999;
    border-radius: 4px;
    cursor: pointer;

    ::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 14px;
        height: 14px;
        border-radius: 50%;
        transition: background-color 0.2s ease;
    }

    :checked::before {
        background-color: green;
    }
`;


export const EventItemWrapper = styled.div`
    overflow-y: auto;
    max-height: 400px; 
    width: 100%;
    padding: 0 20px;

    ::-webkit-scrollbar {
    display: none; 
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`;

export const EventCheckboxInput = styled.input.attrs({ type: 'checkbox' })`
    position: absolute;
    top: 10px;
    right: 10px; 
    z-index: 10;
    appearance: none;
    width: 24px;
    height: 24px;
    border: 2px solid #999;
    border-radius: 4px;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.2s ease;

    &:checked {
        background-color: #0D47A1; 
    }

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        border-radius: 4px;
        transition: width 0.2s ease, height 0.2s ease, background-color 0.2s ease;
    }

    &:checked::before {
        width: 20px;
        height: 20px; 
        background-color: inherit;
    }
`;