import React, {useState} from 'react'
import { UserAuth } from '../../Context/AuthContext'
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { 
  Container, 
  FormButton, 
  FormContent, 
  FormInput, 
  FormLabel, 
  FormWrap, 
  Icon, 
  Form, 
  FormH1, 
  Text 
} from './ForgotPasswordElements'

const ForgotPassword = () => {
  const [email, setEmail] =useState('')
  const [error, setError] =useState('')
  const [message, setMessage] =useState('')
  const [open, setOpen] = React.useState(true);

  const {resetPassword} = UserAuth()

  const handleSubmit = async (e) =>{
      e.preventDefault()
      setError('')
      setMessage('')
      try{
          await resetPassword(email)
          setMessage("Controlla la casella di posta per ulteriori istruzioni")
      }catch(e){
          setError(e.message)
          console.log(e.message)
      }
  }
  return (
    <>
        <Container>
            <FormWrap>
                <Icon to="/">Royal Freedom</Icon>
                <FormContent>
                    <Form action="#" onSubmit={handleSubmit}>
                        <FormH1>Password Dimenticata</FormH1>    
                        
                        {error &&
                        <Box sx={{ width: '100%' }}>
                        <Collapse in={open}>
                          <Alert
                            severity="error"
                            action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                  setOpen(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            sx={{ mb: 2 }}
                          >
                            {error}
                          </Alert>
                        </Collapse>
                        </Box>}

                        {message &&
                        <Box sx={{ width: '100%' }}>
                        <Collapse in={open}>
                          <Alert
                            severity="success"
                            action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                  setOpen(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            sx={{ mb: 2 }}
                          >
                            {message}
                          </Alert>
                        </Collapse>
                        </Box>}

                        
                        <Box sx={{ width: '100%' }}>
                          <Alert severity="warning" sx={{ mb: 2 }}>
                            Attenzione! L'email potrebbe essere nella casella SPAM
                          </Alert>
                        </Box>         

                        <FormLabel htmlFor='for'>Email</FormLabel>
                        <FormInput type='email' onChange={(e) => setEmail(e.target.value)} required/>
                        <FormButton type='submit'>Reset Password</FormButton>
                        <Text to="/signin">Accedi</Text>
                    </Form>
                </FormContent>
            </FormWrap>
        </Container>
    </>
  )
}

export default ForgotPassword
