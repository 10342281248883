import styled from 'styled-components';

export const IconContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); // Crea tante colonne quante ne possono entrare
  grid-gap: 1rem; // Distanza tra gli elementi della griglia
  padding: 1rem;
  overflow-y: auto; // Se gli elementi sono troppi, permetti lo scrolling
  max-height: fit-content;
  width: 100%;
`;

export const Icon = styled.div`
  display: grid;
  grid-template-columns: auto 1fr; // Divide lo spazio per icona e testo
  justify-items: center;
  align-items: center;
  gap: 10px; // Distanza tra icona e testo
  background-color: #2a2f4a; // Sfondo più scuro
  color: #fff; // Testo chiaro per contrasto
  border-radius: 10px;
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  min-height: 100px;

  &:hover {
    background-color: #3a3f6a;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  svg {
    font-size: 2rem; // Icona più grande
    color: #04d49b; // Colore dell'icona, ad esempio azzurro per contrasto
    margin-left: 10px;
  }

  span {
    font-size: 1rem; // Dimensione del testo
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis; // Gestisce il testo troppo lungo
  }
`;






