import React from 'react'
import { ColumnsWrapper, Heading, InfoContainer,ServicesCard, SottoTitolo, Subtitle, TextWrapper, Titolo } from './DiscoverSectionElements'

const DiscoverSection = () => {
  return (
    <>
        <InfoContainer lightBg={false} id='discover'>
                <Titolo>Settori in cui lavoriamo</Titolo>
                <SottoTitolo>In ogni ambito cerchiamo sempre l'innovazione, 
                    con l'obiettivo di rendere ogni ragazzo parte integrante del progetto Royal.
                    Nè un semplice lavoro, nè un semplice investimento, ma un progetto di vita.
                </SottoTitolo>
                <ColumnsWrapper columns={3}>
                    <ServicesCard>
                        <TextWrapper>
                            <Heading>
                                Trading
                            </Heading>
                            <Heading>
                                Copytrading
                            </Heading>
                            <Subtitle>
                                Call di team e private con i leader.
                                Analisi e segnali sui principali asset dei mercati: "Forex", "Indici", "Criptovalute".
                            </Subtitle>
                            
                        </TextWrapper>
                    </ServicesCard>
                    <ServicesCard>
                        <TextWrapper>
                            <Heading>
                                Dropshipping
                            </Heading>
                            <Heading>
                                E-Commerce
                            </Heading>
                            <Subtitle>
                                Call di team e private con i leader.
                                Segnali su offerte e variazioni di prezzo dei prodotti nelle piattaforme di commercio.
                            </Subtitle>
                        </TextWrapper>
                    </ServicesCard>
                    <ServicesCard>
                        <TextWrapper>
                            <Heading>
                                Network 
                            </Heading>
                            <Heading>
                                Marketing
                            </Heading>
                            <Subtitle>
                                Call di team e private con i leader.
                                Formazione dedicata a crearsi un team di persone che condividono gli stessi obiettivi.
                            </Subtitle>
                        </TextWrapper>
                    </ServicesCard>
                </ColumnsWrapper>
        </InfoContainer>
    </>
  )
}

export default DiscoverSection