import styled from "styled-components";
import { MdClose } from 'react-icons/md'

export const Background= styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.8);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
`

export const ModalWrapper = styled.div`
    width: 800px;
    height: 500px;
    box-shadow: 0 5px 16px rgba(0,0,0,0.2);
    background: #fff;
    color: #000;
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    z-index: 4;
    border-radius: 10px;
`

export const ModalImg = styled.img`
    width: 100%;
    height: 100%; 
    border-radius: 10px 0 0 10px;
    background: #000;
`

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.8;
    color: #141414;
`

export const ModalForm = styled.form`
    //background: #010101;
    //max-width: 400px;
    height: auto;
    width: 100%;
    //z-index: 2;
    display: grid;
    margin: 0 auto;
    padding: 20px ;
    //border-radius: 4px;
    //box-shadow: 0 1px 3px rgba(0,0,0,0.9);

    /*@media screen and (max-width: 480px){
       padding: 32px 32px;
    }*/
`

export const ModalButton = styled.button`
    margin-top: 20px;
    background: #141414;
    padding: 10px 24px;
    color: #fff;
    border: none;
    cursor: pointer;
`

export const ModalLabel = styled.label`
    margin-bottom: 1rem;
    font-size: 14px;
`

export const ModalInput = styled.input`
    padding: 16px 16px;
    margin-bottom: 32px;
    border-radius: 4px;
`

export const ModalH1 = styled.h1`
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
`

export const CloseModalButton = styled(MdClose)`
    position: absolute;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
    cursor: pointer;
`