import React, { useState } from 'react'
import {
  GlobalStyle,
  Container,
  Button,
  Item,
  List,
  Image,
  FileName
} from "./PaginaDocumentiElements"
import { UserAuth } from '../../Context/AuthContext';

import {
  ref,
  uploadBytesResumable,
  getDownloadURL 
} from "firebase/storage";
import { storage  } from '../../Firebase'

const PaginaDocumenti = () => {
  const [files, setFiles] = useState([]);

  const [percent, setPercent] = useState(0);

  const { user} = UserAuth();


  const handleFileSelect = (event) => {
    setFiles([...files, ...event.target.files]);
    
  };

  const handleDownload = (index) => {
    const file = files[index];
    const url = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.download = file.name;
    link.click();
  };


  function handleUpload() {
    for (var i = 0; i < files.length; i++) {
      const file = files[i];
    const storageRef = ref(storage,`/files/${file.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
    "state_changed",
    (snapshot) => {
    const percent = Math.round(
    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    );

    // update progress
        setPercent(percent);
    },
    (err) => console.log(err),
    () => {
    // download url
    return getDownloadURL(uploadTask.snapshot.ref).then((url) => {
      //console.log(url);
    });
    }
     );
    }
  }


  /*const Fetchdata = () =>{
    setFiles(['https://firebasestorage.googleapis.com/v0/b/simply-freedom.appspot.com/o/files%2Flibretto_removed.pdf?alt=media&token=a0f9b83d-7fde-46b2-984b-e2fad045888b']); 
    console.log('trippa')
  }*/

  return (
     <>
      <Container>
      <GlobalStyle />
      {user.email === "leofatto9@gmail.com" &&  
      <div>
          <input type="file" onChange={handleFileSelect} multiple/>
          <button onClick={handleUpload}>Carica File</button>
          <p>{percent} "% done"</p>

      </div>
      }
      {user.email === "nicolainnocenti02@gmail.com" && 
      <div>
          <input type="file" onChange={handleFileSelect} multiple />
          <button onClick={handleUpload}>Carica File</button>
          <p>{percent} "% done"</p>
      </div>
      }

      <List>
        {files.map((file, index) => {
          const url = URL.createObjectURL(file);
          return (
            <Item key={index}>
              {file.type === 'application/pdf' ? (
                <Image src="https://via.placeholder.com/150x100.png?text=PDF" />
              ) : (
                <video width="100%" height="70%" controls>
                  <source src={url} type={file.type} />
                </video>
              )}
              <FileName >{ file.name  }</FileName>
              <Button onClick={() => handleDownload(index)}>Download</Button>
            </Item>
          );
        })}
      </List>
      </Container>
     </>
  );
}

export default PaginaDocumenti