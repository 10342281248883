import React, { useContext } from 'react'
import { ContentContext } from './Context/ContentContext';
import PaginaCodici from './PaginaCodici';
import styled from 'styled-components';
import PaginaDocumenti from './PaginaDocumenti';
import ListaDocumenti from './DocumentiUtenti';
import StartBonus from './PaginaStartBonus';
import StartingPlan from './PaginaStartingPlan';
import Team from './PaginaTeam';
import PacchettiSpeciali from './PaginaPacchettiSpeciali';
import PaginaMentorship from './PaginaMentorship';
import Calendario from './PaginaCalendario';
import DashboardIcons from './DashboardIcons';
import InserimentoNotizie from './PaginaNotizie';

const MainContent = React.forwardRef((props, ref) => {
  const { Content } = useContext(ContentContext);

  return (
      <>
       <Container ref={ref}>
       <SubContainer>
        {Content.name === "Dashboard" && <DashboardIcons />}
        {Content.name === "CaricaDoc" && <PaginaDocumenti/>}
        {Content.name === "Documenti" && <ListaDocumenti />}
        {Content.name === "Start" && <StartBonus />}
        {Content.name === "StartingPlan" && <StartingPlan />}
        {Content.name === "Pagamenti" && <h1>Pagamenti</h1>}
        {Content.name === "Team" && <Team />}
        {Content.name === "Calendario" && <Calendario />}
        {Content.name === "Mentorship" && <PaginaMentorship/>}
        
        {Content.name === "Codice" && <PaginaCodici/>}
        {Content.name === "PacchettiSpeciali" && <PacchettiSpeciali/>}
        {Content.name === "InserimentoNotizie" && <InserimentoNotizie/>}
        
        </SubContainer>
        </Container>
      </>
  )
})

const Container = styled.div`
  width: 100%;
  background: linear-gradient() to Bottom right, white 0% right, #e6e4ff 70%;
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
  margin: 1rem 8rem 1rem 4rem;
  display: flex;
  flex-direction    : column;
  @media screen and (min-width:320px) and (max-width:1080px){
      width: 100%;
      margin: 1rem 0 0 0;
  }
`

const SubContainer = styled.div`
  margin-bottom : 0.5rem;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  //gap: 4rem;
  @media screen and (min-width:320px) and (max-width:1080px){
      //height: 100%;
  }
`

/*const SezioneCodici = styled.div`
  height : 100%;
  width: 100%;
  background-color: ${themeColor};
  padding: 1rem;
  border-radius: 1rem;
  color: white;

`*/

export default MainContent