import React, { useState } from 'react'
import { UserAuth } from '../../Context/AuthContext'
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { 
    Container, 
    FormButton, 
    FormContent, 
    FormInput, 
    FormLabel, 
    FormWrap, 
    Icon, 
    Form, 
    FormH1,
    Text
} from './SignUpElements'
import Modal from './Modal';

const Signup = () => {
    const [showModal, setShowModal] =useState(false)

    const [email, setEmail] =useState('')
    const [password, setPassword] =useState('')
    const [confirmPassword, setConfirmPassword] =useState('')
    const [error, setError] =useState('')
    const [codiceAccesso, setCodiceAccesso] =useState('')
    const [open, setOpen] = React.useState(true);

    const {createUser, getAccessCode, setUsedCode} = UserAuth()

    const handleSubmit = async (e) =>{
        e.preventDefault()

        setError('')
        try{
            if (password === confirmPassword){
                  const response = await getAccessCode(codiceAccesso)

                  if (response.exists()){
                    if (!response.data().Admin){
                      if (!response.data().usato){
                        await setUsedCode(codiceAccesso, true)
                        await createUser(email, password, codiceAccesso)
                        openModal()
                      }else{
                        setError("Il codice è già in uso o scaduto!")
                        console.log("Il codice è già in uso o scaduto!")
                      }
                    }else{
                      await createUser(email, password, codiceAccesso)
                      openModal()
                    }
                  }else{
                    setError("No document found!")
                    console.log("No document found!")
                  }

            }
            else{
                setError('le password non corrispondono')
                console.log('le password non corrispondono')
            }
        }catch(e){
            setError(e.message)
            console.log(e.message)
        }
    }

    const openModal = () =>{
      setShowModal(prev=>!prev)
    }

  return (
    <>
        
        <Container>
          
            <FormWrap>
              <Modal showModal={showModal} setShowModal={setShowModal}/>
                <Icon to="/">Royal Freedom</Icon>
                <FormContent>
                    <Form action="#" onSubmit={handleSubmit}>
                        <FormH1>Accesso al tuo account</FormH1>   

                        {error &&
                        <Box sx={{ width: '100%' }}>
                        <Collapse in={open}>
                          <Alert
                            severity="error"
                            action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                  setOpen(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            sx={{ mb: 2 }}
                          >
                            {error}
                          </Alert>
                        </Collapse>
                        </Box>}

                        <FormLabel htmlFor='for'>Email</FormLabel>
                        <FormInput type='email' onChange={(e) => setEmail(e.target.value)} required/>
                        <FormLabel htmlFor='for'>Password</FormLabel>
                        <FormInput type='password' onChange={(e) => setPassword(e.target.value)} required/>
                        <FormLabel htmlFor='for'>Conferma Password</FormLabel>
                        <FormInput type='password' onChange={(e) => setConfirmPassword(e.target.value)} required/>
                        <FormLabel htmlFor='for'>Codice di accesso</FormLabel>
                        <FormInput type='text' onChange={(e) => setCodiceAccesso(e.target.value)} required/>
                        <FormButton type='submit'>Iscriviti</FormButton>
                        <Text to="/signin">Hai già un account? Accedi</Text>
                    </Form>
                </FormContent>
            </FormWrap>
            
        </Container>
    </>
  )
}

export default Signup