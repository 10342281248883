import React from 'react'
import ScrollToTop from '../components/ScrollToTop'
import Signup from '../components/Signup'

const SignUpPage = () => {
  return (
    <>
      <ScrollToTop />
        <Signup />
    </>
  )
}

export default SignUpPage