import styled, { css } from 'styled-components';

export const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 7%;
  right: 20px;
  z-index: 1000;
`;

export const IconButton = styled.button`
  background-color: #25D366;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.26);

  &:focus {
    outline: none;
  }

  &:hover {
    ${({ isOpen }) => isOpen ? css`
        background-color: #293134;
    ` : css`
        background-color: #128C7E;
    `}
  }
`;

export const FullButton = styled.div`
  background-color: #25D366;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 0;
  overflow: hidden;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.26);
  transition: all 0.3s ease;

  ${({ isOpen }) => isOpen ? css`
    padding: 10px 20px;
    border-radius: 30px;
  ` : css`
    padding: 0;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    justify-content: center;
  `}

  &:hover {
    background-color: #128C7E;
  }
`;

export const Text = styled.a`
  color: white;
  text-decoration: none;
  margin-left: 10px;
  display: ${({ isOpen }) => isOpen ? 'inline' : 'none'};
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }
`;
