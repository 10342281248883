import React, { useState } from 'react'
import {
    Container,
    InputContainer,
    Input,
    Button,
    TextCheckBox,
    SelectContainer,
    Select,
    CartoonyOption,
    CheckboxContainer,
    Checkbox,
    Title,
    SaveButton
} from "./PaginaCodiciElements"
import { FaPencilAlt } from 'react-icons/fa';
import { FaCopy } from 'react-icons/fa';
import { UserAuth } from '../../Context/AuthContext'
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
//import { BiSave } from 'react-icons/bi'

const PaginaCodici = () => {
  //const [text, setText] = useState('');
  const [Codice, setCodice] = useState('');
  const [error, setError] =useState('')
  const [success, setSuccess] =useState('')
  const [open, setOpen] = React.useState(true);
  const [seAdmin, setseAdmin] = useState(false);
  const [seGratisBE, setseGratisBE] = useState(false);

  const [pacchetto, setpacchetto] = useState('Silver');

  const {createCode, getAccessCode} = UserAuth()

  const handlepacchettoChange = (event) => {
    setpacchetto(event.target.value);
  };

  const handleCodiceChange = (event) => {
    setCodice(event.target.value);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(Codice);
  };

  /*const handleTextChange = (event) => {
    setText(event.target.value);
  };*/

  const handleseAdminChange = (event) => {
    setseAdmin(!seAdmin);
  };

  const handleseGratisBEChange = (event) => {
    setseGratisBE(!seGratisBE);
  };


  function generateCode() {
    let code = Date.now().toString(36).substr(2, 4); // Genera una stringa univoca basata sul timestamp corrente di 4 cifre
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  
    for (let i = 0; i < 12; i++) {
      code += characters.charAt(Math.floor(Math.random() * characters.length));
    }
  
    return code.match(/.{1,4}/g).join("-");
  }

  const handlePopulateButtonClick = () => {
    setCodice(generateCode());
    setError('');
  };

  const handleSubmit = async (e) =>{
    e.preventDefault()

    try{
        if (!(Codice.trim() === "")){
          const response = await getAccessCode(Codice)
          if (!response.exists()){ 
            await createCode(Codice, seAdmin, seGratisBE, pacchetto);    
            setSuccess('Il codice è stato creato correttamente! '+ Codice)
            setCodice('');  
          }else{
            setError('Il codice esiste già!')
            setCodice(''); 
            setSuccess('')
            console.log('Il codice esiste già!') 
          }
        }
        else{
            setError('Il codice non è compilato!')

            setSuccess('')
            console.log('Il codice non è compilato!')
        }
    }catch(e){
        setError(e.message)

        setSuccess('')
        console.log(e.message)
    }
  }

    //<Input type="checkbox" checked={seAdmin} onChange={handleseAdminChange} />
  return (
    <>
    <Title>Creazione Codici</Title>
                        {error &&
                        <Box sx={{ width: '100%' }}>
                        <Collapse in={open}>
                          <Alert
                            severity="error"
                            action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                  setOpen(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            sx={{ mb: 2 }}
                          >
                            {error}
                          </Alert>
                        </Collapse>
                        </Box>}

                        {success &&
                        <Box sx={{ width: '100%' }}>
                        <Collapse in={open}>
                          <Alert
                            severity="success"
                            action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                  setOpen(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            sx={{ mb: 2 }}
                          >
                            {success}
                          </Alert>
                        </Collapse>
                        </Box>}
    <Container>

      <InputContainer>
        <Input type="text" readOnly value={Codice} onChange={handleCodiceChange}/>
        <Button onClick={handlePopulateButtonClick}>
            <FaPencilAlt />
        </Button>
        <Button onClick={handleCopyClick}>
            <FaCopy />
        </Button>
      </InputContainer>
      
      <CheckboxContainer onClick={handleseAdminChange}>
        <TextCheckBox>
            È un admin?
        </TextCheckBox>
        
        <Checkbox isChecked={seAdmin} />
        
      </CheckboxContainer>
      <CheckboxContainer onClick={handleseGratisBEChange}>
        <TextCheckBox>
            È un ragazzo di BE?
        </TextCheckBox>
        
        <Checkbox isChecked={seGratisBE} />
      </CheckboxContainer>

      <SelectContainer>
        <Select value={pacchetto} onChange={handlepacchettoChange}>
          <CartoonyOption value="Silver">Silver</CartoonyOption>
          <CartoonyOption value="Gold">Gold</CartoonyOption>
          <CartoonyOption value="Diamond">Diamond</CartoonyOption>
          <CartoonyOption value="Gratis">Settimana Gratuita</CartoonyOption>
        </Select>
      </SelectContainer>

      <InputContainer>
        <SaveButton onClick={handleSubmit}>
            Crea Codice
        </SaveButton>
      </InputContainer>

    </Container>



    
    </>
  )
}

export default PaginaCodici