import styled from 'styled-components';
import { themeColor,darkThemeColor } from './../utils';
import { TextField } from '@mui/material';


export const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 2rem;
`;

export const InputContainer = styled.div`
  width: 100%;
  margin: 0.5rem;
`;

export const Input = styled.input`
  padding: 0.5rem;
  margin: 0.5rem;
  margin-left: auto;
  font-size: 1.5rem;
  width: 100%;
  border: 1px solid black;
  border-radius: 0.5rem;
`;

export const TitleInput = styled(Input)`
  // Mantiene lo stile base di Input
`;

export const DescriptionInput = styled.textarea`
  padding: 0.5rem;
  margin: 0.5rem;
  margin-left: auto;
  font-size: 1.5rem;
  width: 100%;
  border: 1px solid black;
  border-radius: 0.5rem;
  height: 40%;
  resize: vertical;
  min-height: 100px;

  @media screen and (min-width:320px) and (max-width:1080px){
    height: 200px;
  }
`;

export const LinkInput = styled(Input)`
`;

export const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin: 50px 0;
  text-transform: uppercase;
  letter-spacing: 2px;

  &:after {
    content: "";
    display: block;
    width: 80px;
    height: 6px;
    margin: 20px auto;
    background-color: ${themeColor};
    border-radius: 10px;
  }
`;

export const SaveButton = styled.button`
    margin-top: 2rem;
    padding : 0.3rem 1rem;
    border-radius: 1rem;
    font-weight: 500;
    font-size: 1.5rem;
    color: white;
    background-color: ${themeColor};
    cursor: pointer;
    align-self: center;
    width: 100%;

    &:hover {
        transition: all 0.2s ease-in-out;    
        background:  ${darkThemeColor};
    }
`

export const DatePickerContainer = styled.div`
  margin: 0.5rem;
  margin-left: auto;
  font-size: 1.5rem;
  width: 100%;
`

export const DatePickerTextField = styled(TextField)({
  width: '100%',
  border: '1px solid black',
  borderRadius: '0.5rem',
  backgroundColor: "white",

  '& .MuiInput-underline:before': {
    borderBottom: 'none',
  },

  '& .MuiInput-underline:after': {
    borderBottom: 'none',
  },

  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
});