import React,{useState, useEffect} from 'react'
import { Calcoli, DivDaCentrare, ListaSelezioniTitleWrapper, 
    ListaSelezioniWrapper, ListaSelezioniWrapperInterno, 
    PacchettiSpecialiContainer, PacchettoPlus, PacchettoPlusBlank, 
    PacchettoPlusCalcoli, PacchettoPlusCalcoliColonna, PacchettoPlusTitleWrapper, 
    PacchettoPlusWrapper, Title, TitoloCalcoli,
    ListItem, TitleLista, Subtitle, CheckboxContainer, CheckboxInput, CheckboxText, WrapListItem } from './PaginaPacchettiSpecialiElements'

const PacchettiSpeciali = () => {

    /* CODICE PER NUOVI PACCHETTI PLUS */
    const [selectedBase, setSelectedBase] = useState("");
    const baseOptions = ["Accademy Base", "Royal Base", "Royal Intermedio", "Royal Avanzato"];

    const [selectedServices, setSelectedServices] = useState({
        Trading: false,
        ECommerce: false,
        ContentCreation: false
    });

    const updateItemsBasedOnSelection = () => {
        // Puoi definire logica personalizzata qui in base alla combinazione
        // per esempio:
        switch(selectedBase) {
          case "Accademy Base":
            /* switch(selectedServices){
              case [{Trading: true, ECommerce: false, ContentCreation: false}]:
                break;
              case [{Trading: true, ECommerce: true, ContentCreation: false}]:
                break;
              case [{Trading: false, ECommerce: true, ContentCreation: false}]:
                break;
              case [{Trading: false, ECommerce: true, ContentCreation: true}]:
                break;
              case [{Trading: false, ECommerce: false, ContentCreation: true}]:
                break;
              case [{Trading: true, ECommerce: false, ContentCreation: true}]:
                break;
              case [{Trading: true, ECommerce: true, ContentCreation: true}]:
                break;
              default:
                ;
            } */
            break;
          case "Royal Base":
            break;
          case "Royal Intermedio":
            break;
          case "Royal Avanzato":
            break;
          default:
            ;
        }
    }

    useEffect(() => {
      updateItemsBasedOnSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedServices]);
    /* CODICE PER NUOVI PACCHETTI PLUS */

    const [items, setItems] = useState([
        { id: 1, title: 'Passività', subtitle: 'Servizio di rendita passivo incluso', value: 12, isSelected: false },
        { id: 2, title: 'Formazioni PRIVATE', subtitle: '8 call private con leader a TUA SCELTA', value: 10, isSelected: false },
        { id: 3, title: 'Linee guida PLUS', subtitle: 'Percorso base privato + Strategie testate', value: 9, isSelected: false },
        { id: 4, title: 'Gruppo Dropshipping', subtitle: 'Segnali su offerte e nuovi prodotti', value: 8, isSelected: false },
        { id: 5, title: 'Gruppo Copytrading VIP', subtitle: '+5 segnali al giorno su Forex, Indici e Crypto', value: 8, isSelected: false },
        { id: 6, title: 'Start Bonus', subtitle: 'Bonus mensile per il tuo Network', value: 7, isSelected: false },
        { id: 7, title: 'Merch in regalo', subtitle: 'Limited edition in regalo al quinto mese', value: 6, isSelected: false },
        { id: 8, title: 'Gruppo Whatsapp', subtitle: 'Gruppo privato del team', value: 5, isSelected: true, isDisabled: true },
        { id: 9, title: 'Formazione di team', subtitle: 'Call di formazione con tutto il team', value: 5, isSelected: true, isDisabled: true },
      ]);
    
      const handleCheckboxChange = (id) => {
        setItems((prevItems) =>
          prevItems.map((item) =>
            item.id === id && !item.isDisabled ? { ...item, isSelected: !item.isSelected } : item
          )
        );
      };

      const calculateTotal = () => {
        let total = 0;
        items.forEach((item) => {
          if (item.isSelected) {
            total += item.value;
          }
        });
        
        return total;
      };

      const calculatePrice = (selectedCount) => {
        if (selectedCount === 2) {
          return 24.99;
        } else if (selectedCount >= 3 && selectedCount <= 5) {
          return 19.99;
        } else if (selectedCount >= 6 && selectedCount <= 7) {
          return 14.99;
        } else if (selectedCount >= 8 && selectedCount <= 9) {
          return 9.99;
        }
        return 0;
      };
    
      const selectedCount = items.filter((item) => item.isSelected).length;
      const prezzoMensile = calculatePrice(selectedCount);

      const selectedItems = items.filter((item) => item.isSelected);
      const selectedTitles = selectedItems.map((item) => item.title);

  return (
    <>
      {/* CODICE PER NUOVI PACCHETTI PLUS */}
      <div>
          {baseOptions.map(option => (
              <label key={option}>
                  <input 
                      type="radio" 
                      value={option} 
                      checked={selectedBase === option} 
                      onChange={(e) => setSelectedBase(e.target.value)} 
                  />
                  {option}
              </label>
          ))}
      </div>

      {selectedBase && (
          <div>
              {Object.keys(selectedServices).map(service => (
                  <label key={service}>
                      <input 
                          type="checkbox" 
                          value={service}
                          checked={selectedServices[service]}
                          onChange={() => setSelectedServices(prev => ({...prev, [service]: !prev[service]}))}
                      />
                      {service}
                  </label>
              ))}
          </div>
      )}
      {/* CODICE PER NUOVI PACCHETTI PLUS */}
        <PacchettiSpecialiContainer>
            <PacchettoPlusWrapper>
                <PacchettoPlus>
                    <PacchettoPlusTitleWrapper>
                        <Title>Plus</Title>
                    </PacchettoPlusTitleWrapper>
                    <PacchettoPlusBlank >
                        {selectedTitles.map((title) => (
                        <ListItem key={title}>
                        <WrapListItem>
                            <TitleLista>- {title}</TitleLista>
                        </WrapListItem>
                        </ListItem>
                        ))}
                    </PacchettoPlusBlank>
                </PacchettoPlus>
                <PacchettoPlusCalcoli>
                    <PacchettoPlusCalcoliColonna>
                        <DivDaCentrare>
                        <TitoloCalcoli>
                            Iscrizione
                        </TitoloCalcoli>
                        <Calcoli>
                            {calculateTotal()} €
                        </Calcoli>
                        </DivDaCentrare>
                    </PacchettoPlusCalcoliColonna>
                    <PacchettoPlusCalcoliColonna>
                        <DivDaCentrare>
                        <TitoloCalcoli>
                            Al mese
                        </TitoloCalcoli>
                        <Calcoli>
                            {prezzoMensile} €
                        </Calcoli>
                        </DivDaCentrare>
                    </PacchettoPlusCalcoliColonna>
                </PacchettoPlusCalcoli>
            </PacchettoPlusWrapper>

            <ListaSelezioniWrapper>
                <ListaSelezioniTitleWrapper>
                    <Title>Servizi</Title>
                </ListaSelezioniTitleWrapper>
                <ListaSelezioniWrapperInterno>

                {items.map((item) => (
                    <ListItem key={item.id}>
                    <WrapListItem>
                        <TitleLista>{item.title}</TitleLista>
                        <Subtitle>{item.subtitle}</Subtitle>
                    </WrapListItem>
                    <CheckboxContainer>
                        <CheckboxInput
                        type="checkbox"
                        checked={item.isSelected}
                        onChange={() => handleCheckboxChange(item.id)}
                        isDisabled={item.isDisabled}
                        />
                        <CheckboxText>{item.value} €</CheckboxText>
                    </CheckboxContainer>
                    </ListItem>
                ))}

                </ListaSelezioniWrapperInterno>
            </ListaSelezioniWrapper>
        </PacchettiSpecialiContainer>
    </>
  )
}

export default PacchettiSpeciali