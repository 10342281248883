import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { 
  Container, 
  FormButton, 
  FormContent, 
  FormInput, 
  FormLabel, 
  FormWrap, 
  Icon, 
  Form, 
  FormH1
} from '../Signup/SignUpElements';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import emailjs from 'emailjs-com';

emailjs.init('0F_2JG7RFl_xEPLF7'); 

const Candidati = ({ idStreamer }) => {
    const emailAziendale = "royalfreedom@outlook.it"

    const navigate = useNavigate();

    const [streamers] = useState([
        { id: 1, name: 'davistaa' },
        { id: 2, name: 'YurKos' },
        { id: 3, name: 'Dave_Bolenta' },
      ]);

    const [streamerCorrente, setStreamerCorrente] = useState({});

    useEffect(() => {
        const numIdStreamer = Number(idStreamer);
        const foundStreamer = streamers.find((s) => s.id === numIdStreamer);
        if (!foundStreamer) {
            navigate("/");
        } else {
            setStreamerCorrente(foundStreamer);
        }
    }, [idStreamer, streamers]);

    const [email, setEmail] =useState('');
    const [nomeCompleto, setNomeCompleto] =useState('');
    const [numeroTel, setNumeroTel] =useState('');
    const [error, setError] =useState('');

    const [open, setOpen] = useState(true);

    const handleSubmit = async (e) =>{
      e.preventDefault()

      setError('')

      try {
        const templateParams = {
          from_name: nomeCompleto,
          to_name: "Royal Freedom",
          to_mail: emailAziendale,
          message: `Vorrei candidarmi tramite il link dello streamer ${streamerCorrente.name}\n
                    Nome Completo: ${nomeCompleto}\nNumero di Telefono: ${numeroTel}\nE-mail: ${email}`
        };
  
        emailjs.send("service_qr7ndtn","template_nf2grtn", templateParams)
          .then(function(response) {
            console.log('SUCCESS!', response.status, response.text);
          }, function(error) {
            setError("Failed to send email: " + error);
            console.log('FAILED...', error);
          });
      }catch(e){
        setError(e.message)
        console.log(e.message)
      }
    }

  return (
    <>
        
        <Container>
          
            <FormWrap>
                <Icon to="/">Royal Freedom</Icon>
                <FormContent>
                    <Form action="#" onSubmit={handleSubmit}>
                        <FormH1>Candidati tramite <b>{streamerCorrente.name}</b></FormH1>   

                        {error &&
                        <Box sx={{ width: '100%' }}>
                        <Collapse in={open}>
                          <Alert
                            severity="error"
                            action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                  setOpen(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            sx={{ mb: 2 }}
                          >
                            {error}
                          </Alert>
                        </Collapse>
                        </Box>}

                        <FormLabel htmlFor='for'>Email</FormLabel>
                        <FormInput type='email' onChange={(e) => setEmail(e.target.value)} required/>
                        <FormLabel htmlFor='for'>Nome e Cognome</FormLabel>
                        <FormInput type='text' onChange={(e) => setNomeCompleto(e.target.value)} required/>
                        <FormLabel htmlFor='for'>Numero di telefono</FormLabel>
                        <FormInput type='text' onChange={(e) => setNumeroTel(e.target.value)} required/>
                        <FormButton type='submit'>Candidati</FormButton>
                    </Form>
                </FormContent>
            </FormWrap>
            
        </Container>
    </>
  )
}

export default Candidati