import React, {useState, useEffect} from 'react'
import Calendar from 'react-calendar';
import './CustomCalendar.css'
import { UserAuth } from '../../Context/AuthContext';
import { CalendarContainer, CheckboxController, CloseModalButton, Container, DeleteButton, EventBottom, EventCheckboxInput, EventDate, EventDateString, EventForm, EventHeader, EventHour, EventItem, EventItemWrapper, EventList, EventName, LeaderTitle, Leaders, LeftColumn, ModalBackground, ModalContainer, OptionsContainer, RightColumn, StyledButton, StyledCheckbox, StyledInput, SubTitle, Time, TimeItem, TimeList, TitleModal } from './CalendarioElements';
import { Select, MenuItem, FormControl, FormHelperText } from '@mui/material';

const formatDateToString = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Aggiungi 1 perché i mesi sono da 0 a 11
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const formatDateToStringITA = (date) => {
  const dateParts = date.split("-");
  const newDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;

  return newDate;
}

const getLastWeek = () => {
  const today = new Date();
  const lastMonday = new Date(today);
  lastMonday.setDate(today.getDate() - today.getDay() + 1 - 7); // Lunedì scorso

  const lastSunday = new Date(today);
  lastSunday.setDate(today.getDate() - today.getDay() + 7 - 7); // Domenica scorsa

  return {
      start: lastMonday,
      end: lastSunday
  };
}

const Calendario = () => {
    const {getEventsForCurrentMonth, addEvent, markEventAsCompleted, user, deleteLastWeekEvents} = UserAuth();

    const [date, setDate] = useState(new Date());
    const [currentMonth, setCurrentMonth] = useState((new Date()).getMonth() + 1);
    const [currentYear, setCurrentYear] = useState((new Date()).getFullYear());
    const [selectedDay, setSelectedDay] = useState(new Date().getDay());
    const [selectedTime, setSelectedTime] = useState(null);
    const orari = ['09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00'];
    const [availableTimes, setAvailableTimes] = useState([
      { chiaveGiorno: 1, orariDisponibili: orari },
      { chiaveGiorno: 2, orariDisponibili: orari },
      { chiaveGiorno: 3, orariDisponibili: orari },
      { chiaveGiorno: 4, orariDisponibili: orari },
      { chiaveGiorno: 5, orariDisponibili: orari },
    ]);
    const [eventName, setEventName] = useState('');
    const [eventSurname, setEventSurname] = useState('');
    const [eventsList, setEventsList] = useState([]);
    const [giorniSettimana] = useState([
      {chiaveGiorno: 1, nome: 'Lunedì'},
      {chiaveGiorno: 2, nome: 'Martedì'},
      {chiaveGiorno: 3, nome: 'Mercoledì'},
      {chiaveGiorno: 4, nome: 'Giovedì'},
      {chiaveGiorno: 5, nome: 'Venerdì'},
    ]);
    const leaders =[
      { id: 1, nomeLeader: "Leonardo Fattorini", mail: "leofatto9@gmail.com", admin: true },
      { id: 2, nomeLeader: "Nicola Innocenti", mail: "nicolainnocenti02@gmail.com", admin: true },
      { id: 3, nomeLeader: "Michele Vanacore", mail: "miky.vana.2003@gmail.com", admin: false },
      { id: 4, nomeLeader: "Nicolas Menin", mail: "menin.nm17@gmail.com", admin: false },
      { id: 5, nomeLeader: "Diego Marinini", mail: "mario.cominelli23@gmail.com", admin: false },
    ];
    const leadersSchedule = [
      { leaderId: 1, disponibilita: {1: ['14:00', '18:00', '19:00', '20:00', '21:00'], 
                                    2: ['14:00', '18:00', '19:00', '20:00'],
                                    3: ['09:00', '10:00', '11:00', '12:00'], 
                                    4: ['14:00', '18:00', '19:00', '20:00'],
                                    5: ['09:00', '10:00', '11:00', '12:00'],
                                    }  },
      { leaderId: 2, disponibilita: {1: ['18:00', '19:00', '20:00', '21:00'], 
                                    2: ['13:00', '14:00', '18:00', '19:00', '20:00'],
                                    3: ['18:00', '19:00', '20:00'], 
                                    4: ['13:00', '14:00', '19:00', '20:00'],
                                    5: ['13:00', '14:00', '19:00', '20:00', '21:00'],
                                    }  },
      { leaderId: 3, disponibilita: {1: ['14:00', '15:00', '16:00', '17:00', '18:00'], 
                                    2: ['17:00', '18:00'],
                                    3: ['09:00', '10:00', '11:00', '12:00'], 
                                    4: ['14:00', '15:00', '16:00', '17:00', '18:00'],
                                    5: ['09:00', '10:00', '11:00', '12:00', '14:00', '15:00', '16:00', '17:00', '18:00'],
                                    } },
      { leaderId: 4, disponibilita: {1: ['10:00', '11:00', '12:00', '13:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00'], 
                                    2: [],
                                    3: ['10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00'], 
                                    4: [],
                                    5: ['10:00', '11:00', '12:00', '13:00', '16:00', '17:00', '18:00'],
                                    } },
      { leaderId: 5, disponibilita: {1: ['17:00', '18:00', '19:00', '20:00', '21:00'], 
                                    2: ['16:00', '17:00', '18:00', '19:00', '20:00'],
                                    3: ['17:00', '18:00', '19:00', '20:00', '21:00'], 
                                    4: ['10:00', '11:00', '12:00'],
                                    5: [],
                                    }  },
    ];
    const [selectedLeader, setSelectedLeader] = useState(null);
    const [showAllEvents, setShowAllEvents] = useState(true);
    const isAdmin = leaders.some((leader) => leader.mail === user.email && leader.admin);
    const [leaderError, setLeaderError] = useState(false);

  useEffect(() => {
    setCurrentMonth(date.getMonth() + 1);
  }, [date]);

  const fetchDeleteEvents = async () => {
    try {
      const lastWeek = getLastWeek();
      await deleteLastWeekEvents(formatDateToString(lastWeek.start), formatDateToString(lastWeek.end));
      
      setTimeout(fetchEvents, 1000);
    } catch (error) {
      console.error('Errore durante la cancellazione o il recupero degli eventi:', error);
    }
  };

  const handleDateClick = (selectedDate) => {
    // Gestisci il clic sulla data qui
    if (selectedDate.getDay() !== 0 && selectedDate.getDay() !== 6) {
      setDate(selectedDate);
      setSelectedDay(selectedDate.getDay());
      setSelectedTime(null); 
    } else {
      setDate(selectedDate);
      setSelectedDay(null);
      setSelectedTime(null);
    }
  };

  const handleMonthClick = ({ activeStartDate }) => {
    setCurrentMonth(activeStartDate.getMonth() + 1);
    setCurrentYear(activeStartDate.getFullYear());
  };

  const handleTimeClick = (time) => {
    // Gestisci il clic sull'orario qui
    const isTimeOccupied = eventsList.some(
        (evento) => evento.data === date && evento.orario === time
      );
  
      if (!isTimeOccupied) {
        setSelectedTime(time);
      } else {
        alert('L\'orario è già occupato da un evento.');
        setSelectedTime(null);
      }
  };

  const handleAggiungiEvento = async () => {
    if (!selectedLeader) {
      setLeaderError(true);
      return;
    }

    setLeaderError(false);

    const nuovoEvento = {
      giornoSettimana: selectedDay,
      data: formatDateToString(date),
      orario: selectedTime,
      nome: eventName,
      cognome: eventSurname,
      completata: false,
      leader: selectedLeader.id,
    };

    if (selectedDay !== 0 && selectedDay !== 6 && selectedDay !== -1) {
      const updatedAvailableTimes = [...availableTimes];
      
      const dayTimes = updatedAvailableTimes[selectedDay - 1].orariDisponibili;
      if (!dayTimes.includes(selectedTime)) { 
        dayTimes.push(selectedTime);
        setAvailableTimes(updatedAvailableTimes);
      }
    }

    if (nuovoEvento) {
      await addEvent(nuovoEvento);
      fetchEvents();
    }

    setEventsList([...eventsList, nuovoEvento]); // Aggiungi l'evento alla lista
    setEventName('');
    setEventSurname('');
    setSelectedTime(null); // Resetta l'orario selezionato
    setSelectedLeader(null);
  };

  const fetchEvents = async () => {
    const events = await getEventsForCurrentMonth(currentYear, currentMonth);
    setEventsList(events);
  };

  useEffect(() => {
    fetchEvents(); // Richiama fetchEvents quando cambia currentMonth
  }, [currentMonth]);

  // const getOccupiedTimes = (selectedDate) => {
  //   const dateString = formatDateToString(selectedDate);
  //   const eventsOnSelectedDate = eventsList.filter(event => event.data === dateString);
  //   return eventsOnSelectedDate.map(event => event.orario);
  // };

  const handleEventCompletion = async (evento, completedValue) => {
    await markEventAsCompleted(evento.data, evento.orario, completedValue); 
    fetchEvents(); 
  };

  const closeModal = () => {
    setSelectedTime(null);
    setSelectedLeader(null);
  };

  const filteredEvents = () => {
    if(isAdmin && showAllEvents) {
      return eventsList;
    }
    return eventsList.filter(e => e.leader === leaders.find(l => l.mail === user.email).id);
  };

  const handleLeaderChange = (event) => {
    const leaderId = event.target.value;
    const selectedLeader = leaders.find(leader => leader.id === leaderId);
    setSelectedLeader(selectedLeader || null);
  };

  function getAvailableLeaders(day, time) {
    const availableLeaders = leaders.filter(leader => {
      const schedule = leadersSchedule.find(s => s.leaderId === leader.id);
      if (!schedule) return false; 
      
      if (!schedule.disponibilita) return true;
      
      return schedule.disponibilita[day] && schedule.disponibilita[day].includes(time);
    });

    const leadersWithoutEvents = availableLeaders.filter(leader => {
      const hasEvent = eventsList.some(event => {
        const eventMatch = event.data === formatDateToString(date) &&
                           event.orario === time &&
                           event.leader === leader.id;
        return eventMatch;
      });
    
      return !hasEvent;
    });

    return leadersWithoutEvents
  }

  // function getAvailableLeadersNames(day, time) {
  //   return getAvailableLeaders(day, time).map(leader => leader.nomeLeader);
  // }

  const accessoCalendario = user.email === "leofatto9@gmail.com" || user.email === "nicolainnocenti02@gmail.com" ||
            user.email === "miky.vana.2003@gmail.com" || user.email === "menin.nm17@gmail.com" ||
            user.email === "mario.cominelli23@gmail.com";
  

  return (
    <Container>
      <LeftColumn>
      <CalendarContainer>
        <Calendar
          onClickDay={handleDateClick}
          onActiveStartDateChange={handleMonthClick}
          value={date}
        />
      </CalendarContainer>

      { accessoCalendario && 
      <EventList>
        <SubTitle>Eventi</SubTitle>
          {isAdmin && (
            <OptionsContainer>
              <DeleteButton onClick={fetchDeleteEvents}>Cancella eventi settimana scorsa</DeleteButton>
              <CheckboxController>
                <StyledCheckbox checked={showAllEvents} onChange={() => setShowAllEvents(!showAllEvents)} />
                Mostra tutti gli eventi
              </CheckboxController> 
            </OptionsContainer>
          )}
          <EventItemWrapper>
            {filteredEvents().map((evento, index) => (
              <EventItem key={index} style={{ backgroundColor: evento.completata ? 'green' : 'red' }}>
                <EventHeader>
                  <EventName>
                  {evento.nome} {evento.cognome}
                  </EventName>
                  <EventHour>
                  Orario: {evento.orario}
                  </EventHour>
                </EventHeader>
                <EventBottom>
                  <EventDateString>
                  Data:
                  </EventDateString>
                  <EventDate>
                  {giorniSettimana.find((giorno) => giorno.chiaveGiorno === evento.giornoSettimana).nome} {formatDateToStringITA(evento.data)}
                  </EventDate>
                </EventBottom>
                <EventCheckboxInput
                  type="checkbox"
                  checked={evento.completata}
                  onChange={(e) => handleEventCompletion(evento, e.target.checked)}
                />
              </EventItem>
            ))}
          </EventItemWrapper>
      </EventList> }
      </LeftColumn>

      <RightColumn>
      {selectedDay && selectedDay !== 0 && selectedDay !== 6 && (
        <>
        <SubTitle>Orari disponibili per il giorno {giorniSettimana.find((giorno) => giorno.chiaveGiorno === selectedDay).nome}</SubTitle>
        <TimeList>
            {availableTimes
              .find((item) => item.chiaveGiorno === selectedDay)
              .orariDisponibili.map((orario, index) => {
                const availableLeaders = getAvailableLeaders(selectedDay, orario);

                const isTimeFullyOccupied = availableLeaders.every(leader =>
                  eventsList.some(event => 
                    event.data === formatDateToString(date) &&
                    event.orario === orario &&
                    event.leader === leader.id
                  )
                );

                const isOccupied = isTimeFullyOccupied || (availableLeaders.length < 1);
                return (
                  <TimeItem
                    key={index}
                    onClick={() => !isOccupied && handleTimeClick(orario)}
                    style={{ cursor: isOccupied ? 'not-allowed' : 'pointer' }}
                  >
                    <Time style={{ color: isOccupied ? 'red' : 'black' }}>
                        {orario}
                    </Time>
                    {!isOccupied && (
                      <>
                        <LeaderTitle>Leaders disponibili:</LeaderTitle>
                        <Leaders>
                            {availableLeaders.map(leader => leader.nomeLeader).join(', ')}
                        </Leaders>
                      </>
                    )}
                  </TimeItem>
                );
              })}
        </TimeList>
        </>
      )}
      </RightColumn>

      {selectedTime && selectedDay !== 0 && selectedDay !== 6 && (
        <ModalBackground show={selectedTime !== null} onClick={closeModal}>
        <ModalContainer onClick={(e) => e.stopPropagation()}>
          <TitleModal>Aggiungi un evento per il giorno {giorniSettimana.find((giorno) => giorno.chiaveGiorno === selectedDay).nome} ({formatDateToString(date)}) alle {selectedTime}</TitleModal>
          <CloseModalButton onClick={closeModal}>X</CloseModalButton>
          <EventForm onSubmit={(e) => {
            e.preventDefault();
            handleAggiungiEvento();
          }}>
            <StyledInput
              type="text"
              placeholder="Nome"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
              required
            />
            <StyledInput
              type="text"
              placeholder="Cognome"
              value={eventSurname}
              onChange={(e) => setEventSurname(e.target.value)}
              required
            />
            
            <FormControl fullWidth sx={{marginBottom:"15px"}}>
                <Select
                  labelId="leader-select-label"
                  id="leader-select"
                  value={selectedLeader ? selectedLeader.id : ''}
                  onChange={handleLeaderChange}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Seleziona un leader
                  </MenuItem>
                  {getAvailableLeaders(selectedDay, selectedTime).map(leader => (
                    <MenuItem key={leader.id} value={leader.id}>
                      {leader.nomeLeader}
                    </MenuItem>
                  ))}
                </Select>
                {leaderError && <FormHelperText sx={{color:"red", fontSize:"15px"}}>Devi selezionare un leader</FormHelperText>}
            </FormControl>

            <StyledButton type="submit">Aggiungi Evento</StyledButton>
          </EventForm>
        </ModalContainer>
        </ModalBackground>
      )}

      
      
    </Container>
  );
}

export default Calendario