import React, {useState} from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import HeroSection from '../components/HeroSection'
import InfoSection from '../components/InfoSection/Index'
import { /* homeObjOne, */ homeObjThree, homeObjTwo } from '../components/InfoSection/Data'
import Services from '../components/Services'
import Footer from '../components/Footer'
import DiscoverSection from '../components/DiscoverSection'
import FloatingButtonHome from '../components/FloatingButtonHome'

const Home = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    } 

  return (
    <>
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <Navbar toggle={toggle}/>
        <HeroSection />
        <DiscoverSection />
        {/* <InfoSection {...homeObjOne}/> */}
        <InfoSection {...homeObjTwo}/>
        <Services />
        <InfoSection {...homeObjThree}/>
        <FloatingButtonHome 
          text="Contattaci via Whatsapp!"
          link="https://api.whatsapp.com/send?phone=393311168570&text=Ciao!%20Mi%20piacerebbe%20entrare%20a%20far%20parte%20del%20tuo%20team%2C%20vorrei%20avere%20pi%C3%B9%20info%20a%20riguardo%F0%9F%92%8E" 
        />
        <Footer/>
    </> 
  )
}

export default Home