import './App.css';
import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './Pages/index';
import SignInPage from './Pages/signin'
import SignUpPage from './Pages/signup'
import { AuthContextProvider } from './Context/AuthContext';
import Dashboard from './Pages/account';
import ProtectedRoute from './components/ProtectedRoute';
import ForgotPassword from './components/ForgotPassword/index';
//import MyCookieConsent from './CookieConsent';
import CandidatiPage from './Pages/candidati';
//import Pagamento from './Pages/pagamentoPage';

function App() {
  //const [cookiesAccepted, setCookiesAccepted] = useState(false);

  // const handleCookiesAccepted = () => {
  //   setCookiesAccepted(true);
  // };

  // const handleCookiesDeclined = () => {
  //   setCookiesAccepted(false);
  // };

  return (
    <>
    <Router>
      <AuthContextProvider>
      <Routes>
        <Route exact path='/' element={<Home/>}/>
        <Route exact path='/candidati/:id' element={<CandidatiPage/>}/>
        <Route exact path='/signin' element={<SignInPage/>}/>
        <Route exact path='/signup' element={<SignUpPage/>}/>
        <Route exact path='/dashboard' element={<ProtectedRoute><Dashboard/></ProtectedRoute>}/>
        <Route exact path='/forgot-password' element={<ForgotPassword/>}/>
        {/* <Route exact path='/pagamento' element={<Pagamento/>}/> */}
      </Routes>
      </AuthContextProvider>
    </Router>
    {/* {!cookiesAccepted && (
        <MyCookieConsent
          onAccept={handleCookiesAccepted}
          onDecline={handleCookiesDeclined}
        />
      )} */}
    </>
    
  );
}

export default App;
