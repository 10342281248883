import React, {useState} from 'react'
import Video from '../../Video/Video2.mp4'
import { Button } from '../ButtonElement'
import { 
    HeroContainer, 
    HeroBg,
    VideoBg,
    HeroBtnWrapper,
    HeroContent,
    ArrowForward,
    ArrowRight,
    HeroH1,
    HeroP
} from './HeroElements'    

const HeroSection = () => {
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }

  return (
    <HeroContainer id ='home'>
        <HeroBg>
            <VideoBg autoPlay loop muted playsInline src={Video} type='video/mp4' />
        </HeroBg>   
        <HeroContent>
            <HeroH1>Freedom is not a word, it’s an action.</HeroH1>
            <HeroP>
            Royal freedom è una Azienda Italiana, di formazione nei vari settori del mondo online. Ideata e aperta a tutti, da chi parte da zero senza budget, fino ai più esperti che vogliono raggiungere importanti traguardi in breve tempo  
            </HeroP>
            <HeroBtnWrapper>
                <Button to="signup"
                onMouseEnter={onHover} 
                onMouseLeave={onHover}
                primary = "true"
                dark="true"
                smooth = {true} 
                duration = {500} 
                spy = {true} 
                exact = 'true' 
                offset = {-80}
                >
                    Inizia ora {hover ? <ArrowForward/> : <ArrowRight/>}
                </Button>
            </HeroBtnWrapper>
        </HeroContent> 
    </HeroContainer>
  )
}

export default HeroSection