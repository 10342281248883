import React, { useState } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  IconButton,
  ButtonWrapper,
  FullButton,
  Text,
} from "./FloatingButtonElements";

const FloatingButtonHome = ({ text, link }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleButton = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <ButtonWrapper>
      <FullButton isOpen={isOpen} onClick={isOpen ? () => window.open(link, '_blank') : toggleButton}>
        <IconButton isOpen={isOpen} onClick={toggleButton}>
          <WhatsAppIcon style={{ color: 'white', fontSize: '30px' }} />
        </IconButton>
        <Text isOpen={isOpen} href={link} target="_blank" rel="noopener noreferrer">
          {text}
        </Text>
      </FullButton>
    </ButtonWrapper>
  );
};

export default FloatingButtonHome;
