export const homeObjOne = {
    id: 'discover',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Royal Freedom',
    headline: 'Maturare, Formarsi e Guadagnare, lavorando in un Team...',
    description: 'Amicizia, Lavoro di squadra, Professionalità... Sono alla base del nostro progetto. Assieme a noi PARTI da ZERO, e ARRIVI ai tuoi OBIETTIVI.',
    buttonLabel: 'Inizia ora',
    imgStart: false,
    img: require('../../Images/svg-3.svg'),
    alt: 'Chart',
    dark: true,
    primary: true,
    darkText: false,
    darkLink: true,
    linkBtn: 'home',
    linkPage: ''
};

/* export const homeObjOne = {
    id: 'discover',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Avrò tempo? Farà davvero al caso mio? Mi troverò bene con gli altri ragazzi?',
    headline: 'Iscriviti gratis per 7 giorni',
    description: 'Tutte ansie e dubbi che ognuno di noi ha avuto prima di partire in questo progetto. Ecco perchè abbiamo creato questa SETTIMANA GRATUITA...',
    buttonLabel: 'Candidati ora',
    imgStart: false,
    img: require('../../Images/svg-3.svg'),
    alt: 'Chart',
    dark: true,
    primary: true,
    darkText: false,
    darkLink: true,
    linkBtn: 'https://docs.google.com/forms/d/e/1FAIpQLSeDLS1D5S8nC0eCR7OJzIdg4EhEYNd8LUn9F1qlOeNhMPmacw/viewform?usp=sf_link',
    linkPage: '',

    bottoneEsternoVisibile: true,
    sottotitoloVisibile: true,
    sottotitolo: 'Avrò tempo? Farà davvero al caso mio?  Mi troverò bene con gli altri ragazzi?... '
}; */

export const homeObjTwo = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'La nostra ubicazione',
    headline: 'Il nostro progetto nasce ad Arezzo, una piccola città della splendida Toscana',
    description: 'Due amici, un unico grande OBIETTIVO...',
    buttonLabel: 'Scopri di più',
    imgStart: true,
    img: require('../../Images/world.svg'),
    alt: 'World',
    dark: false,
    primary: false,
    darkText: true,
    darkLink: false,
    linkBtn: 'discover',
    linkPage: ''
};

export const homeObjThree = {
    id: 'signup',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Entra nel Team',
    headline: 'Creare un account è semplice e veloce',
    description: "Prepara tutto in meno di 15 minuti. tutto ciò che devi fare è aggiungere le tue informazioni e sei pronto per partire.",
    buttonLabel: 'Iscriviti',
    imgStart: false,
    img: require('../../Images/svg-1.svg'),
    alt: 'Chart3',
    dark: false,
    primary: false,
    darkText: true,
    darkLink: false,
    linkBtn: '',
    linkPage: '/signup'
};