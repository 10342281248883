import styled from 'styled-components'

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #010606;
    padding: 40px;

    @media screen and (max-width: 768px) {
    }

    @media screen and (max-width: 480px) {
    }
`

export const ColumnsWrapper =  styled.div`
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns:  ${({columns})=> (columns ? 'repeat('+columns+', 1fr)' : 'repeat(3, 1fr)')};;
    align-items: center;
    grid-gap: 16px;
    padding: 50px;

    @media screen and (max-width: 1000px) {
       grid-template-columns: 1fr ;
    }

    @media screen and (max-width: 768px) {
       grid-template-columns: 1fr;
       padding: 0 20px;
    }
`

export const ServicesCard = styled.div`
    background: #01bf71;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    min-width: 30%;
    max-height: 830px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;

    &:hover{
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }

    @media screen and (max-width: 1000px) {
        min-width: 90%;
        max-height: 400px;
    }

    @media screen and (max-width: 768px) {
        min-width: 90%;
        max-height: 400px;
    }
`

export const TextWrapper = styled.div`
    max-width: 100%;
`

export const Heading = styled.h1`
    margin-bottom: 14px;
    font-size: 40px;
    line-height: 1.1;
    font-weight: 600;
    color: white;

    @media screen and (max-width: 480px) {
        font-size: 24px;
    }
`

export const Subtitle = styled.p`
    max-width: 440px;
    margin-top: 10%;
    font-size: 22px;
    line-height: 24px;
    color:  #010606;

    @media screen and (max-width: 480px) {
        font-size: 20px;
    }
`
export const Titolo = styled.h1`
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 25px;
    margin-top: 32px;
    text-align: center;

    @media screen and (max-width: 480px) {
        font-size: 2rem;
    }
`

export const SottoTitolo = styled.h2`
    font-size: 1.2rem;
    color: #01bf71;
    margin-bottom: 50px;
    text-align: center;
    width: 70%;
    text-transform: uppercase;

    @media screen and (max-width: 480px) {
        font-size: 0.8rem;
    }
`

