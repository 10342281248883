import styled from 'styled-components';

export const PacchettiSpecialiContainer = styled.div`
    height: 100%;
    display: flex;
    //flex-direction: column;
    width: 100%;
    align-self: center;
    @media screen and (min-width:320px) and (max-width:1080px){
        flex-direction: column;
    } 
`;

export const PacchettoPlusWrapper = styled.div`
    //height: 700px;
    height: 100%;
    width: 47%;
    display: flex;
    border-radius: 40px;
    flex-direction: column;
    @media screen and (min-width:320px) and (max-width:1080px){
        width: 100%;
        height: 700px;
    }
`;

export const PacchettoPlus = styled.div`
    height: 82%;
    background-color: #ef7820;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    display: flex;
    flex-direction: column;
`;

export const PacchettoPlusTitleWrapper = styled.div`
    height: 15%;
    background-color: transparent;
    display: flex;
    align-items: center;
`;
export const PacchettoPlusBlank = styled.div`
    height: 85%;
    background-color: white;
    padding: 5%;
`;

export const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  color: white;
  text-align: left;
  margin-left: 5%;
  text-transform: uppercase;
  letter-spacing: 2px;
  
`;

export const TitoloCalcoli = styled.h1`
  font-size: 20px;
  color: white;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
`;
export const Calcoli = styled.h1`
  font-size: 30px;
  font-weight: bold;
  color: white;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

export const PacchettoPlusCalcoli = styled.div`
    height: 18%;
    background-color: #ef7820;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    display: flex;
    flex-direction: row;
`;

export const PacchettoPlusCalcoliColonna = styled.div`
    //margin-top: 10px;
    height: 100%;
    width: 50%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const DivDaCentrare = styled.div`
`

export const ListaSelezioniWrapper = styled.div`
    //height: 700px;
    flex: 1;
    margin-left: 6%;
    width: 47%;
    display: flex;
    border-radius: 40px;
    flex-direction: column;
    background-color: #ef7820;
    @media screen and (min-width:320px) and (max-width:1080px){
        width: 100%;
        margin-left: 0%;
        margin-top: 20px;
    }
`;

export const ListaSelezioniTitleWrapper = styled.div`
    height: 12.3%;
    background-color: transparent;
    display: flex;
    align-items: center;
`;

export const ListaSelezioniWrapperInterno = styled.div`
    height: 87.7%;
    // flex: 1 1 auto;
    background-color: white;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    padding: 10px;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
`;

export const WrapListItem = styled.div`
  margin: 0;
  width: 100%;
`

export const TitleLista = styled.h3`
  margin: 0;
  width: 75%;
`;

export const Subtitle = styled.p`
  margin: 0;
  width: 75%;
`;

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  width: 25%;
`;

export const CheckboxInput = styled.input`
  appearance: none;
  width: 24px;
  height: 24px;
  position: relative;
  border: 2px solid #999;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;

  ::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 14px;
    height: 14px;
    border-radius: 50%;
    transition: background-color 0.2s ease;
  }

  :checked::before {
    background-color: green;
  }
`;

export const CheckboxText = styled.span`
  flex-grow: 1;
`;
