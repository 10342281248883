import React/* , { useState, useEffect } */ from 'react'
/* import FileList from './FileList';
import { storage } from '../../Firebase'
import { ref, listAll, getDownloadURL } from 'firebase/storage'; */
import styled from 'styled-components';
import { themeColor, darkThemeColor } from '../utils';

const Button = styled.a`
  display: block;
  margin: 0 auto;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  //background-color: blue;
  background-color: ${darkThemeColor};
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    //background-color: darkblue;
    background-color: ${themeColor};
  }
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin: 50px 0;
  text-transform: uppercase;
  letter-spacing: 2px;

  &:after {
    content: "";
    display: block;
    width: 80px;
    height: 6px;
    margin: 20px auto;
    background-color: ${themeColor};
    border-radius: 10px;
  }`;


const ListaDocumenti = () => {

    /* const [imageUrls, setImageUrls] = useState([]);

    useEffect(() => {
        // Recupera il riferimento al bucket di Firebase Storage
        const imagesRef = ref(storage, 'files/');
    
        // Recupera tutti i file nella cartella "images"
        listAll(imagesRef)
          .then((result) => {
            const urls = [];
    
            // Per ogni file trovato, recupera l'URL pubblico e lo aggiunge alla lista
            result.items.forEach((imageRef) => {
              getDownloadURL(imageRef)
                .then((url) => {
                  urls.push( { name: (imageRef.name).replace('.pdf',''), url: url });
                  urls.sort((a, b) => a.name.localeCompare(b.name));
                })
                .catch((error) => {
                  console.log(error);
                });
            });
    
            setImageUrls(urls);
          })
          .catch((error) => {
            console.log(error);
          });
      }, []); */

  return (
    //<FileList files={imageUrls} />
    <>
    <Title>Documenti</Title>
    <Button href="https://rhinestone-filament-cd7.notion.site/Video-Royal-Freedom-0b8b21758f2f411fa41cf412dedf7e74" target="_blank">
      Visualizza Documenti
    </Button>
    {/* <iframe
      title="Notion Embed"
      src="https://laser-principle-342.notion.site/Royal-Freedom-Documenti-Video-0ccabccaeac6438aade1a8ae6c34b225"
      width="100%"
      height="500px"
      frameBorder="0"
    /> */}
    </>
  )
}

export default ListaDocumenti