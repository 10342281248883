import React from 'react'
import {Button, ButtonlinkEsterno} from '../ButtonElement'
import { 
    Column2, 
    Img, 
    ImgWrap,
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap,
    BtnLink
} from './InfoElements'

const InfoSection = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, darkLink,
    description, buttonLabel, img, alt, primary, dark, dark2, linkBtn, linkPage
    ,bottoneEsternoVisibile}) => {
  return (
    <>
        <InfoContainer lightBg={lightBg} id= {id}>
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1>
                        <TextWrapper>
                            <TopLine>{topLine}</TopLine>
                            <Heading lightText={lightText}>{headline}</Heading>
                            <Subtitle darkText={darkText}>{description}</Subtitle>
                            <BtnWrap>
                                { bottoneEsternoVisibile && (
                                    <ButtonlinkEsterno
                                    href={linkBtn}
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact="true"
                                    offset={-80}
                                    primary={primary ? 1 : 0}
                                    dark= {dark ? 1 : 0}
                                    dark2= {dark2 ? 1 : 0}
                                    bottoneVisibile = {bottoneEsternoVisibile ? 1 : 0}
                                    ><BtnLink darkLink= {darkLink} to={linkPage}>{buttonLabel}</BtnLink></ButtonlinkEsterno>
                                )}
                                { !bottoneEsternoVisibile && (
                                    <Button 
                                    to={linkBtn} 
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact="true"
                                    offset={-80}
                                    primary={primary ? 1 : 0}
                                    dark= {dark ? 1 : 0}
                                    dark2= {dark2 ? 1 : 0}
                                    ><BtnLink darkLink= {darkLink} to={linkPage}>{buttonLabel}</BtnLink></Button>
                                )}
                                
                            </BtnWrap>
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <ImgWrap>
                            <Img src= {img} alt = {alt}/>
                        </ImgWrap>
                    </Column2>
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
    </>
  )
}

export default InfoSection