import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Container, InputContainer, TitleInput, DescriptionInput, LinkInput, Title, SaveButton, DatePickerTextField, DatePickerContainer } from './PaginaNotizieElements';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { UserAuth } from '../../Context/AuthContext';

const formatDateToString = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Aggiungi 1 perché i mesi sono da 0 a 11
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const InserimentoNotizie = () => {
  const {addNotizia} = UserAuth();

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [open, setOpen] = useState(true);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [date, setDate] = useState(dayjs());

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!title || !date || !description){
        setError('Il titolo, la descrizione e la data sono obbligatori!');
      }

      const nuovaNotizia = {
        titolo: title,
        descrizione: description,
        link: link,
        data: formatDateToString(date.toDate()),
      };

      if (nuovaNotizia){
        await addNotizia(nuovaNotizia);
        setSuccess('Notizia creata con successo!');
        setError('');
      } else {
        setError('Qualcosa è andato storto durante la creazione della notizia!');
      }
      
      setTitle('');
      setDescription('');
      setLink('');
      setDate('');
    } catch (e) {
      setError(e.message);
      setSuccess('');
      console.log(e.message);
    }
  };

  return (
    <>
      <Title>Nuova Notizia</Title>
      {error && (
        <Box sx={{ width: '100%' }}>
          <Collapse in={open}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                    setError('');
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {error}
            </Alert>
          </Collapse>
        </Box>
      )}

      {success && (
        <Box sx={{ width: '100%' }}>
          <Collapse in={open}>
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {success}
            </Alert>
          </Collapse>
        </Box>
      )}

      <Container>
        <form onSubmit={handleSubmit}>
          <InputContainer>
            <TitleInput
              type="text"
              placeholder="Titolo *"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />

            <DescriptionInput
              type="text"
              placeholder="Descrizione *"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />

            <LinkInput
              type="text"
              placeholder="Link (opzionale)"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />

            <DatePickerContainer>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Data *"
                  inputFormat='DD-MM-YYYY'
                  value={date}
                  onChange={(newValue) => setDate(newValue)}
                  renderInput={(params) => <DatePickerTextField {...params} />}
                />
              </LocalizationProvider>
            </DatePickerContainer>


            <SaveButton type="submit">Crea Notizia</SaveButton>
          </InputContainer>
        </form>
      </Container>
    </>
  );
};

export default InserimentoNotizie;
