import React, {useRef, useState} from 'react'
import { 
    ModalButton,
    Background,
    ModalWrapper,
    ModalContent,
    ModalImg,
    ModalLabel,
    //CloseModalButton,
    ModalH1,
    ModalInput,
    ModalForm
} from './ModalElements'
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { UserAuth } from '../../Context/AuthContext';
import { useNavigate } from 'react-router-dom'

export const Modal = ({showModal, setShowModal}) =>{

    const [selectedDate, setSelectedDate] = useState(dayjs('0000-00-00'));
    const [nomeCompleto, setNomeCompleto] = useState('')
    const [numeroTel, setNumeroTel] = useState('')
    const [error, setError] =useState('')
    const modalRef = useRef()
    const [open, setOpen] = React.useState(true);

    const {user, updateUser} = UserAuth()

    const navigate = useNavigate()

    /*const closeModal = e =>{
        if(modalRef.current === e.target){
            setShowModal(false);
        }
    }*/

    const handleSubmit = async (e) =>{
        e.preventDefault()

        setError('')
        try{
            await updateUser(user.uid, nomeCompleto, numeroTel, selectedDate.toString())
            
            navigate('/dashboard')
        }catch(e){
            setError(e.message)
            console.log(e.message)
        }
    }

    return(
       <>
        {showModal ? (
            <Background ref={modalRef} /*onClick={closeModal}*/>
                <ModalWrapper showModal={showModal}>
                    <ModalImg src={require('../../Images/svg-Modal.svg')} alt='image'/>
                    <ModalContent> 
                        <ModalForm action="#" onSubmit={handleSubmit}>
                        <ModalH1>Completa l'iscrizione</ModalH1>

                        {error &&
                        <Box sx={{ width: '100%' }}>
                        <Collapse in={open}>
                          <Alert
                            severity="error"
                            action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                  setOpen(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            sx={{ mb: 2 }}
                          >
                            {error}
                          </Alert>
                        </Collapse>
                        </Box>}

                        <ModalLabel htmlFor='for'>Nome e Cognome</ModalLabel>
                        <ModalInput type='Text' onChange={(e) => setNomeCompleto(e.target.value)} required/>
                        <ModalLabel htmlFor='for'>Numero di telefono</ModalLabel>
                        <ModalInput type='tel' onChange={(e) => setNumeroTel(e.target.value)} required/>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            padding='16px'
                            disableFuture
                            label="Data di nascita"
                            openTo="day"
                            views={['day', 'month', 'year']}
                            value={selectedDate}
                            onChange={(newValue) => {
                                setSelectedDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />   
                        </LocalizationProvider>
                        <ModalButton type='submit'>Completa iscrizione</ModalButton>
                        </ModalForm>
                    </ModalContent>
                    {/*<CloseModalButton aria-label='Chiudi' onClick={()=>setShowModal(prev => !prev)}/>*/}
                </ModalWrapper>
            </Background>
        ): null}

        
           
       </> 
    )   
}

export default Modal