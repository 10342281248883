import React from 'react';
import styled from 'styled-components';
import { themeColor, darkThemeColor } from '../utils';

const Button = styled.a`
  display: block;
  margin: 0 auto;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  //background-color: blue;
  background-color: ${darkThemeColor};
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    //background-color: darkblue;
    background-color: ${themeColor};
  }
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin: 50px 0;
  text-transform: uppercase;
  letter-spacing: 2px;

  &:after {
    content: "";
    display: block;
    width: 80px;
    height: 6px;
    margin: 20px auto;
    background-color: ${themeColor};
    border-radius: 10px;
  }`;


const StartBonus = () => {
    return (
        <>
        <Title>Start Bonus</Title>
        <Button href="https://laser-principle-342.notion.site/START-BONUS-ISCRIZIONI-GRATUITE-676909b22e2149acbc4096091ebf34f3" target="_blank">
          Vai allo Start Bonus
        </Button>
        </>
      )
}

export default StartBonus;