import React, { createContext, useState } from "react";

export const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
  const [Content, setContent] = useState({name: "Dashboard"});

  return (
    <ContentContext.Provider value={{ Content, setContent }}>
      {children}
    </ContentContext.Provider>
  );
};