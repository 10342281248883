import React, {useContext} from 'react'
import { ContentContext } from '../Context/ContentContext';
import { RiFileCopyLine, RiTeamLine } from "react-icons/ri";
import { GiBullseye } from "react-icons/gi";
import { FaChalkboard, FaFlagCheckered } from "react-icons/fa";
import { BsCodeSlash, BsCalendarEvent } from "react-icons/bs";
import { IconContainer, Icon } from './DashboardIconsElements';
import { UserAuth } from '../../Context/AuthContext';

const DashboardIcons = () => {
  const { setContent } = useContext(ContentContext);
  const { user } = UserAuth();

  return (
    <IconContainer>
      {/* <Icon onClick={() => setContent({ name: 'Dashboard' })}><RiHomeLine/><span>Dashboard</span></Icon> */}
      <Icon onClick={() => setContent({ name: 'StartingPlan' })}><GiBullseye/><span>Starting Plan</span></Icon>
      <Icon onClick={() => setContent({ name: 'Documenti' })}><RiFileCopyLine/><span>Documenti</span></Icon>
      <Icon onClick={() => setContent({ name: 'Start' })}><FaFlagCheckered/><span>Start Bonus</span></Icon>
      <Icon onClick={() => setContent({ name: 'Team' })}><RiTeamLine/><span>Team</span></Icon>
      <Icon onClick={() => setContent({ name: 'Calendario' })}><BsCalendarEvent/><span>Calendario</span></Icon>
      <Icon onClick={() => setContent({ name: 'Mentorship' })}><FaChalkboard/><span>Mentorship</span></Icon>
      {(user.email === "leofatto9@gmail.com" || user.email === "nicolainnocenti02@gmail.com") &&
          <>
            <Icon onClick={() => setContent({ name: 'Codice' })}><BsCodeSlash/><span>Crea Codice</span></Icon>
            <Icon onClick={() => setContent({ name: 'PacchettiSpeciali' })}><BsCodeSlash/><span>Pacchetti</span></Icon>
          </>
        }
      
    </IconContainer>
  )
}

export default DashboardIcons