import React , { useContext } from 'react';
import { 
    ProfileContainer,
    Container,
    Avatar,
    Name,
    FormButton,
    LinksContainer,
    Links,
    Link,
    ContactContainer,
    Contact,
    ContactLink
  } from './SidebarElements';
import profilePic from '../Images/profilePic.svg';
import { UserAuth } from '../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { RiHomeLine, RiFileCopyLine } from "react-icons/ri";
//import { FaWallet } from "react-icons/fa";
import { RiTeamLine } from "react-icons/ri";
import { ContentContext } from './Context/ContentContext';
import { BsCodeSlash } from "react-icons/bs"
import { FaChalkboard, FaFlagCheckered } from "react-icons/fa"
import { GiBullseye } from "react-icons/gi"
import { BsCalendarEvent } from "react-icons/bs"
//import { useState } from 'react';


function Sidebar({ mainContentRef }) {
  const { Content, setContent } = useContext(ContentContext);
  const { user,logout} = UserAuth()

  //const { user, setUser } = useContext(AuthContext);
  //const nome = getUser(user.uid);
  const navigate = useNavigate()

  const handleLogout = async () =>{
    try{
      await logout()
      navigate('/')
      console.log('Hai effettuato il logout')
    }catch(e){
      console.log(e.message)
    }
  }

  //const [selectedMenu, setSelectedMenu] = useState("Dashboard");

  const handleMenuClick = (name) => {
    setContent({ name });
    //setSelectedMenu(name);

    if (window.innerWidth <= 1080 && mainContentRef && mainContentRef.current) {
      mainContentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  //const [utenteNome , setUtenteNome] = useState('')
  //const [utentePacchetto , setUtentePacchetto] = useState('')

  /*window.addEventListener('load', () => {
    Fetchdata();
  });*/

  //const Fetchdata = async (e) =>{
    /*auth.onAuthStateChanged(function(user) {
      if (user) {
        setUtenteID(user.uid)
      } else {  
        console.log('No User')
      }
    })*/
    //const response = await getUtenteDashboard( user.uid);
    //console.log('utente: ' + response.data().nomeCognome)
    //<Name > {utenteNome} </Name>
    //<Name > {utentePacchetto} </Name>
    //setUtenteNome(response.data().nomeCognome)
    //setUtentePacchetto(response.data().pacchetto)
  //}

  return (
    
    <Container >
      <ProfileContainer >
          <Avatar src = {profilePic}/>
          <Name > {user.email} </Name>
          <FormButton onClick={
              handleLogout
          }>Logout</FormButton>
      </ProfileContainer>
      <LinksContainer>
        <Links>
          <Link onClick={() => handleMenuClick("Dashboard")} active={Content.name === "Dashboard"}>
            <RiHomeLine/>
            <h3>Dashboard</h3>
          </Link>
          <Link onClick={() => handleMenuClick("StartingPlan")} active={Content.name === "StartingPlan"}>
            <GiBullseye/>
            <h3>Starting Plan</h3>
          </Link>
          <Link onClick={() => handleMenuClick("Documenti")} active={Content.name === "Documenti"}>
            <RiFileCopyLine/>
            <h3>Documenti</h3>
          </Link>
          <Link onClick={() => handleMenuClick("Start")} active={Content.name === "Start"}>
            <FaFlagCheckered/>
            <h3>Start Bonus</h3>
          </Link>
          {/* <Link onClick={() => setContent({ name: "Pagamenti" })}>
            <FaWallet/>
            <h3>Pagamenti</h3>
          </Link> */}
          <Link onClick={() => handleMenuClick("Team")} active={Content.name === "Team"}>
            <RiTeamLine/>
            <h3>Team</h3>
          </Link>
          <Link onClick={() => handleMenuClick("Calendario")} active={Content.name === "Calendario"}>
            <BsCalendarEvent/>
            <h3>Calendario</h3>
          </Link>
          <Link onClick={() => handleMenuClick("Mentorship")} active={Content.name === "Mentorship"}>
            <FaChalkboard/>
            <h3>Mentorship</h3>
          </Link>

          {user.email === "leofatto9@gmail.com" &&
          <>
          <Link onClick={() => handleMenuClick("Codice")} active={Content.name === "Codice"}>
            <BsCodeSlash/>
            <h3>Crea Codice</h3>
          </Link>
          <Link onClick={() => handleMenuClick("PacchettiSpeciali")} active={Content.name === "PacchettiSpeciali"}>
            <BsCodeSlash/>
            <h3>Pacchetti</h3>
          </Link>
          <Link onClick={() => handleMenuClick("InserimentoNotizie")} active={Content.name === "InserimentoNotizie"}>
            <BsCodeSlash/>
            <h3>Nuova Notizia</h3>
          </Link>
          </> 
          }
          {user.email === "nicolainnocenti02@gmail.com" && 
          <>
          <Link onClick={() => handleMenuClick("Codice")} active={Content.name === "Codice"}>
            <BsCodeSlash/>
            <h3>Crea Codice</h3>
          </Link>
          <Link onClick={() => handleMenuClick("PacchettiSpeciali")} active={Content.name === "PacchettiSpeciali"}>
            <BsCodeSlash/>
            <h3>Pacchetti</h3>
          </Link>
          <Link onClick={() => handleMenuClick("InserimentoNotizie")} active={Content.name === "InserimentoNotizie"}>
            <BsCodeSlash/>
            <h3>Nuova Notizia</h3>
          </Link>
          </> 
          }

          {/* {user.email === "leofatto9@gmail.com" && 
            <Link onClick={() => setContent({ name: "CaricaDoc" })}>
            <BsCodeSlash/>
            <h3>Carica Documenti</h3>
          </Link>
          }
          {user.email === "nicolainnocenti02@gmail.com" && 
          <Link onClick={() => setContent({ name: "CaricaDoc" })}>
            <BsCodeSlash/>
            <h3>Carica Documenti</h3>
          </Link>
          } */}

        </Links>
        <ContactContainer>
          <Contact>Hai bisogno di aiuto?</Contact>
          <ContactLink href="#contattaci">Contattaci</ContactLink>
        </ContactContainer>
      </LinksContainer>
    </Container>
  )
}

export default Sidebar
