import styled from "styled-components";
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  //align-items: center;
  //justify-content: center;
  height: 100vh;
`;

export const List = styled.ul`
  list-style: none;
  width: 100%;
  margin: 20px 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 800px;
`;

export const Item = styled.li`
  width: 30%;
  height: 400px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ddd;
`;
export const FileName = styled.p`
  width: 80%;
  margin: 10px 0;
  text-align: center;
  overflow: hidden;
  height: 15%;
`;

export const Image = styled.img`
  width: 100%;
  height: 70%;
`;

export const Button = styled.button`
  width: 80%;
  margin: 10px 0;
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  height: 15%;
`;