import React from 'react';
import { 
    SidebarContainer, 
    Icon, 
    CloseIcon,
    SideBtnWrap,
    SidebarWrapper,
    SidebarLink,
    SidebarMenu,
    SidebarRoute, 
    SidebarRouteEsterno
} from './SidebarElements';

const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />  
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                
                <SidebarLink to="discover" onClick={toggle}
                smooth = {true} 
                duration = {500} 
                exact = 'true' 
                offset = {-80}
                >Scopri</SidebarLink>
                <SidebarLink to="about" onClick={toggle}
                smooth = {true} 
                duration = {500} 
                exact = 'true' 
                offset = {-80}
                >Dove Siamo</SidebarLink>
                <SidebarLink to="services" onClick={toggle}
                smooth = {true} 
                duration = {500} 
                exact = 'true' 
                offset = {-80}
                >Chi Siamo</SidebarLink>
                <SidebarLink to="signup" onClick={toggle}
                smooth = {true} 
                duration = {500} 
                exact = 'true' 
                offset = {-80}
                >Iscriviti</SidebarLink>
            </SidebarMenu>
            <SideBtnWrap>
                <SidebarRouteEsterno href='https://docs.google.com/forms/d/e/1FAIpQLSeDLS1D5S8nC0eCR7OJzIdg4EhEYNd8LUn9F1qlOeNhMPmacw/viewform?usp=sf_link'>Candidati</SidebarRouteEsterno>
            </SideBtnWrap>    
            <SideBtnWrap>
                <SidebarRoute to="/signin">Accedi</SidebarRoute>
            </SideBtnWrap>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar