import styled from 'styled-components';
import { FaLock } from 'react-icons/fa';

export const MentorshipContainer = styled.div`
    height: 100%;
    display: flex;
    width: 100%;
    align-self: center;
    flex-wrap: wrap;
    overflow-y: auto;
    @media screen and (min-width:320px) and (max-width:1080px){
        flex-direction: column;
    } 

    ::-webkit-scrollbar {
    width: 0.5em;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
`;

export const MentorshipWrapper = styled.div`
    width: 90%;
    height: 50%;
    background-color: #ef7820;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    position: relative;
    @media screen and (min-width:320px) and (max-width:1080px){
        width: 90%;
        height: 1000px;
        align-self: center;
    } 
`;

export const LockIcon = styled(FaLock)`
    position: absolute;
    width: 10%;
    height: 10%;
    top: 8%;
    right: 8%;
    z-index: 10;
    filter: drop-shadow(0 0 20px white);
`;

export const Mask = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 5;
  border-radius: 40px;
`;

export const MentorshipTitleWrapper = styled.div`
  height: 12%;
  background-color: transparent;
  display: flex;
  align-items: center;
`

export const MentorshipContent = styled.div`
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    z-index: 1;

    @media screen and (min-width:320px) and (max-width:1080px){
        flex-direction: column;
    } 
`;


export const InfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  & > * {
    margin-bottom: 5%; 
  }

  & > *:last-child {
    margin-bottom: 0;
  }
  
  @media screen and (min-width:320px) and (max-width:1080px) {
    text-align: center;
  }
`;

export const OreContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
`;

export const InsegnanteFoto = styled.img`
  width: 40%;
  display: block;
  background-size: cover;
  background-position: center; 

  @media screen and (min-width:320px) and (max-width:1080px) {
    margin-top: 5%;
    width: 30%;
  }
`;

export const InfoText = styled.p`
  color: black;
  text-align: center;
  letter-spacing: 1px;
  font-size: 18px;
`;

export const NomeCategoriaOre = styled.p`
  color: #ef7820;
`;

export const InfoOre = styled.p`
  color: black;
`;

export const CategoriaOre = styled.div`
  width: 70%;
  border: 2px solid #ccc;
  padding: 15px 10px;
  margin: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  position: relative;
  transition: border-color 0.3s, box-shadow 0.3s;

  &:hover {
    border-color: #ef7820;
    box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.2);
  }

  .nomeCategoria {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white; 
    padding: 1px 10px;
    z-index: 1;
    font-weight: 600;
    border: 2px solid #ccc;
    border-radius: 40px;
  }
`;

export const NomeMentorship = styled.h1`
  font-weight: bold;
  color: white;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

export const Divider = styled.div`
  height: 80%;
  width: 1%;
  background-color: #ef7820;
  margin: 0 10px;
  border-radius: 40px;

  @media screen and (min-width:320px) and (max-width:1080px) {
    width: 80%;
    height: 1%;
    margin: 10px 0;
  }
`;