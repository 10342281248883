import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { UserAuth } from '../../Context/AuthContext'
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
//import {GoogleButton} from 'react-google-button';
import { 
    Container, 
    FormButton, 
    FormContent, 
    FormInput, 
    FormLabel, 
    FormWrap, 
    Icon, 
    Form, 
    FormH1, 
    Text/*, 
    GoogleButtonWrap*/
} from './SignInElements'
import { useEffect } from 'react';
//import { isMobile } from 'react-device-detect';

const Signin = () => {
    const [email, setEmail] =useState('')
    const [password, setPassword] =useState('')
    const [error, setError] =useState('')
    const [open, setOpen] = React.useState(true);

    const {signin} = UserAuth()

    const {/*googleSignInDesktop, googleSignInMobile,*/ user} = UserAuth()

    const navigate = useNavigate()

    const handleSubmit = async (e) =>{
        e.preventDefault()
        setError('')
        try{
            await signin(email, password)
            /*navigate('/dashboard')*/
        }catch(e){
            setError(e.message)
            console.log(e.message)
        }
    }

    /*const handleGoogleSignInDesktop = async () =>{
      setError('')
      try{
          await googleSignInDesktop()
      }catch(e){
          setError(e.message)
          console.log(e.message)
      }
    }

    
    const handleGoogleSignInMobile = async () =>{
      setError('')
      try{
          await googleSignInMobile()
      }catch(e){
          setError(e.message)
          console.log(e.message)
      }
    }*/

    

    useEffect(()=>{
        if(user != null){
            navigate('/dashboard')
        }
    },[user])

  return (
    <>
        <Container>
            <FormWrap>
                <Icon to="/">Royal Freedom</Icon>
                <FormContent>
                    <Form action="#" onSubmit={handleSubmit}>
                        <FormH1>Accesso al tuo account</FormH1>

                        {error &&
                        <Box sx={{ width: '100%' }}>
                        <Collapse in={open}>
                          <Alert
                            severity="error"
                            action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                  setOpen(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            sx={{ mb: 2 }}
                          >
                            {error}
                          </Alert>
                        </Collapse>
                        </Box>}

                        <FormLabel htmlFor='for'>Email</FormLabel>
                        <FormInput type='email' onChange={(e) => setEmail(e.target.value)} required/>
                        <FormLabel htmlFor='for'>Password</FormLabel>
                        <FormInput type='password' onChange={(e) => setPassword(e.target.value)} required/>
                        <FormButton type='submit'>Accedi</FormButton>

                        {/*<GoogleButtonWrap>
                          <GoogleButton onClick={isMobile ? handleGoogleSignInMobile : handleGoogleSignInDesktop}/>
                            </GoogleButtonWrap>*/}

                        <Text to="/forgot-password">Password dimenticata?</Text>
                        <Text to="/signup">Non hai un account? Registrati</Text>
                    </Form>
                </FormContent>
            </FormWrap>
        </Container>
    </>
  )
}

export default Signin