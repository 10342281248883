import React from 'react'
import { auth, db} from '../Firebase'
import { GoogleAuthProvider, signInWithPopup, signInWithRedirect } from 'firebase/auth'
import { createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { signOut } from 'firebase/auth'
import { onAuthStateChanged } from 'firebase/auth'
import { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { doc, serverTimestamp, setDoc, getDoc, updateDoc,
    collection, query, where, getDocs, orderBy, addDoc, deleteDoc } from 'firebase/firestore'


const AuthContext = React.createContext()


export const UserAuth =()=>{
    return useContext(AuthContext)
}

export const AuthContextProvider = ({children}) => {

    const [user, setUser] = useState({})

    /*const uploadFiles = (file) => {
        const storageRef = ref(storage,`/files/${file.name}`)
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
        "state_changed",
        (snapshot) => {
        const percent = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
            setPercent(percent);
        },
        (err) => console.log(err),
        () => {
        // download url
        return getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            return url;
        });
        }
      );
    }*/

    const markEventAsCompleted = async (date, time, completedValue) => {
        const eventsCollection = collection(db, 'events');
    
        // Costruisci la query utilizzando sia la data che l'ora per identificare l'evento univoco
        const q = query(
            eventsCollection,
            where('data', '==', date),
            where('orario', '==', time)
        );
    
        // Esegui la query e ottieni i risultati
        const querySnapshot = await getDocs(q);
        
        // Se esiste un documento che corrisponde, aggiorna il campo 'completato'
        querySnapshot.forEach((document) => {
            const docRef = doc(db, 'events', document.id);
            updateDoc(docRef, { completata: completedValue });
        });
    };

    const getEventsForCurrentMonth = async (currentYear, currentMonth) => {
        const eventsCollection = collection(db, 'events');
  
        const startDate = `${currentYear}-${String(currentMonth).padStart(2, '0')}-01`; // primo giorno del mese
        const endDate = `${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-01`; // primo giorno del mese successivo

        const q = query(
            eventsCollection,
            where('data', '>=', startDate),
            where('data', '<', endDate),
            orderBy('data')
        );

        const querySnapshot = await getDocs(q);
        const events = [];
        querySnapshot.forEach((doc) => {
            events.push(doc.data());
        });

        return events;
    };

    const deleteLastWeekEvents = async (lastMonday, lastSunday) => {
        const eventsRef = collection(db, 'events');

        const q = query(
            eventsRef,
            where('data', '>=', lastMonday), 
            where('data', '<=', lastSunday) 
        );
    
        const querySnapshot = await getDocs(q);
        
        querySnapshot.forEach((document) => {
            const docRef = doc(db, 'events', document.id);
            deleteDoc(docRef);
        });
    };

    const addEvent = async (event) => {
        const eventsCollection = collection(db, 'events');
        await addDoc(eventsCollection, event);
    };


    const getNewsForCurrentMonth = async (currentYear, currentMonth) => {
        const newsCollection = collection(db, 'news');
  
        const startDate = `${currentYear}-${String(currentMonth).padStart(2, '0')}-01`; // primo giorno del mese
        const endDate = `${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-01`; // primo giorno del mese successivo
        
        const q = query(
            newsCollection,
            where('data', '>=', startDate),
            where('data', '<', endDate),
            orderBy('data')
        );

        const querySnapshot = await getDocs(q);
        const news = [];
        querySnapshot.forEach((doc) => {
            news.push(doc.data());
        });

        return news;
    };


    const addNotizia = async (novita) => {
        const newsCollection = collection(db, 'news');
        await addDoc(newsCollection, novita);
    };

    const getAccessCode = (accessCode) =>{
        const docRef = doc(db,"codes", accessCode)
        return getDoc(docRef)
    }

    const getUtenteDashboard = (id) =>{
        const docRef = doc(db,"users", id)
        return getDoc(docRef)
    }

    const setUsedCode=(accessCode, used)=>{
        const ref = doc(db, "codes", accessCode);
        return updateDoc(ref, {
            usato : used,
            timeStampModifica:serverTimestamp()
          });
    }

    const updateUser = (userId, nomeCompleto, numeroTel, dataNascita) => {
        const ref = doc(db, "users", userId);
        return updateDoc(ref, {
            nomeCognome : nomeCompleto,
            numeroTelefono : numeroTel,
            dataDiNascita : dataNascita,
            timeStampModifica:serverTimestamp()
          });
    }

    const googleSignInDesktop =()=>{
        const provider = new GoogleAuthProvider()
        signInWithPopup(auth, provider).then(
            (userCredential) => {
               setDoc(doc(db, "users", userCredential.user.uid), {
                timeStamp:serverTimestamp()
               })
            }
        )
    }

    const googleSignInMobile =()=>{
        const provider = new GoogleAuthProvider()
        signInWithRedirect(auth, provider).then(
            (userCredential) => {
               setDoc(doc(db, "users", userCredential.user.uid), {
                timeStamp:serverTimestamp()
               })
            }
        )
    }
    
    const createUser =(email, password, codiceAccesso)=>{
        return createUserWithEmailAndPassword(auth, email, password).then(
            (userCredential) => {
               setDoc(doc(db, "users", userCredential.user.uid), {
                pacchetto: codiceAccesso,
                timeStamp:serverTimestamp()
               })
            }
        )
    }

    const createCode = (Codice, seAdmin, seGratisBE, pacchetto) =>{
        return setDoc(doc(db, "codes", Codice), {
            Admin : seAdmin,
            GratisBE : seGratisBE,
            Pacchetto: pacchetto,
            timeStamp:serverTimestamp()
           })
    }

    

    const signin = (email,password)=>{
        return signInWithEmailAndPassword(auth,email,password) 
    }

    const logout = () =>{
        return signOut(auth)
    }

    const resetPassword = (email) =>{
        return sendPasswordResetEmail(auth,email)
    }

    useEffect(()=>{
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser)
        })

        return () =>{
            unsubscribe()
        }
    },[])
    
    const value = {
        createUser,
        user,
        logout,
        signin,
        resetPassword,
        googleSignInDesktop,
        googleSignInMobile,
        getAccessCode,
        updateUser,
        setUsedCode,
        getUtenteDashboard,
        createCode, 
        getEventsForCurrentMonth,
        addEvent,
        markEventAsCompleted,
        deleteLastWeekEvents,
        addNotizia,
        getNewsForCurrentMonth
    }

    return(
      <AuthContext.Provider value={value}>
        {children}
      </AuthContext.Provider>  
    )

}