import React from 'react'
import Icon1 from '../../Images/profilePic.svg'
import Icon2 from '../../Images/profilePic.svg'
import { 
    ServicesContainer,
    ServicesH1,
    ServicesH2,
    ServicesCard,
    ServicesIcon,
    ServicesP,
    ServicesWrapper
 } from './ServicesElements' 

const Services = () => {
  return (
    <>
        <ServicesContainer id="services">
            <ServicesH1>I Fondatori</ServicesH1>  
            <ServicesWrapper columns={2}>
                <ServicesCard>
                    <ServicesIcon src={Icon1}/>
                    <ServicesH2>Leonardo Fattorini</ServicesH2>
                    {/* <ServicesP>Forex Trader</ServicesP>
                    <ServicesP>Doppiatore e Programmatore</ServicesP> */}
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon2}/>
                    <ServicesH2>Nicola Innocenti</ServicesH2>
                   {/*  <ServicesP>Index Trader & Networker</ServicesP>
                    <ServicesP>Graphic Designer e Influencer</ServicesP> */}
                </ServicesCard>
            </ServicesWrapper>

            <ServicesH1>I Top Leader</ServicesH1>  
            <ServicesWrapper columns={3}>
                <ServicesCard>
                    <ServicesIcon src={Icon1}/>
                    <ServicesH2>Michele Vanacore</ServicesH2>
                    <ServicesP>Networker</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon2}/>
                    <ServicesH2>Daniele Cannizzaro</ServicesH2>
                    <ServicesP>Trader</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon2}/>
                    <ServicesH2>Andrea Vitellozzi</ServicesH2>
                    <ServicesP>E-Commerce & Dropshipping</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
    </>
  )
}

export default Services