import React from 'react'
import { animateScroll as scroll } from 'react-scroll';
import {
    FaInstagram, FaTiktok
} from 'react-icons/fa';
import { 
    FooterContainer,
    FooterWrap,
    /* FooterLinkItems,
    FooterLinkTitle,
    FooterLinksContainer,
    FooterLink,
    FooterLinksWrapper, */
    SocialIcons,
    SocialIconLink,
    SocialLogo,
    SocialMedia,
    SocialMediaWrap,
    WebsiteRigths,
    //FooterLinkEsterno
} from './FooterElements'

const Footer = () => {

    const toggleHome = () => {
        scroll.scrollToTop();
      }

  return (
    <>
        <FooterContainer>
            <FooterWrap>
                {/* <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>OPPORTUNITÀ</FooterLinkTitle>
                                <FooterLinkEsterno href='https://docs.google.com/forms/d/e/1FAIpQLSeDLS1D5S8nC0eCR7OJzIdg4EhEYNd8LUn9F1qlOeNhMPmacw/viewform?usp=sf_link' >Candidati ora</FooterLinkEsterno>
                        </FooterLinkItems>

                        <FooterLinkItems>
                            <FooterLinkTitle>CHI SIAMO</FooterLinkTitle>
                                <FooterLink to="">Contatti</FooterLink>
                                <FooterLink to="">Dove Siamo</FooterLink>
                        </FooterLinkItems>

                        <FooterLinkItems>
                            <FooterLinkTitle>TERMINI E SUPPORTO</FooterLinkTitle>
                                <FooterLink to="">Terms of Use</FooterLink>
                                <FooterLink to="">Supporto</FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>

                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>LEGAL</FooterLinkTitle>
                                <FooterLink to="">Privacy Policy</FooterLink>
                                <FooterLink to="">Cookies Policy</FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                */}
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/' onClick={toggleHome}>
                            Royal Freedom
                        </SocialLogo>
                        <WebsiteRigths>
                            Royal Freedom © {new Date().getFullYear()} All rights reserved.<br/>
                            Via dei Palazzetti, 29, 52100 Arezzo, Italia | P. IVA IT02459400517
                        </WebsiteRigths>
                        <SocialIcons>

                            <SocialIconLink href="//www.instagram.com/royalfreedom_" target="_blank"
                            aria-label="Instagram">
                                <FaInstagram/>
                            </SocialIconLink>

                            <SocialIconLink href="//www.tiktok.com/@royalfreedom_?_t=8hYSKYZA7Yf&_r=1" target="_blank"
                            aria-label="Tiktok">
                                <FaTiktok/>
                            </SocialIconLink>

                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    </>
  )
}

export default Footer