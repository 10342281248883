import styled from "styled-components";
import { themeColor, darkThemeColor } from './utils';


export const Container = styled.div`
    width: 20%;
    height: 100% !important;
    border-radius : 2rem;
    background-color: #091322;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    @media screen and (min-width:320px) and (max-width:1080px){
       width : 100%;
       height: max-content !important;
    }
`;

export const ProfileContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
`;

export const Avatar = styled.img`
    height: 10rem;
    border-radius: 6rem;
    margin-top: 10%;

    /*@media screen and (max-width: 768px){
        height: 4rem;  
    }*/
`;



export const Name = styled.h1`
    color: white;
    font: 1.5rem;
    font-size: 25px;
    font-weight: 400;
    margin: 0.8rem 0 0.5rem 0;
    padding: 0;
    margin-bottom: 1rem;
    width : 70%;
    overflow: hidden;
    
    /* width: 100%;
    display: inline-block;
    max-width: 200px;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */

    /*@media screen and (max-width: 768px){
        font-size: 8px;   
    }*/
`;


export const FormButton = styled.button`
    padding : 0.3rem 1rem;
    border-radius: 1rem;
    font-weight: 500;
    font-size: 20px;
    color: white;
    background-color: ${themeColor};
    cursor: pointer;

    /*@media screen and (max-width: 768px){
        font-size: 10px;  
    }*/
`

export const LinksContainer = styled.div`
    background-color: ${darkThemeColor};
    height: 100%;
    width: 100%;
    border-radius: 2rem;
    padding: 0 0 10% 0;

`

export const Links = styled.ul`
    list-style-type: none;
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    //height: 60%;
`

export const Link = styled.li`
    margin-left: 25%;
    margin-bottom: 2rem;
    display: flex;
    gap: 1rem;
    color: ${props => props.active ? themeColor : "#e4e4e4"};
    cursor: pointer;

    h3{
       font-weight : 300;
    }
    svg{
        font-size: 1.1rem;
        margin-top: 1.5%;
    }
`

export const ContactContainer = styled.div`
    width: 80%;
    background-color: #091322;
    color: #c4c4c4;
    //height: 20%;
    margin: auto auto;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    //margin-top: 20%;

    a{
        color: white;
        text-decoration: none;
        margin-top: 5%;
        
    }


    @media screen and (min-width:320px) and (max-width:1080px){
        //height: 20%;
        //margin: 12% auto;
        //margin-top: 3rem;
    }
`

export const Contact = styled.span`
    //flex: 1;
`

export const ContactLink = styled.a`
    color: white;
    //flex: 1;
`